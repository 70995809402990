import apiRepository from './apiRepository'
// import Wrapper from './Wrapper'
// import ENUM_SETUP from '../enum/typeSetup'
// import axios from 'axios'
// const varian1 = () => {
//   return {
//     token: '',
//     deviceId: Date.now()
//   }
// }

// const varian2 = () => {
//   return {
//     token: '12345',
//     deviceId: Date.now()
//   }
// }

// const configDevice = () => {
//   return {
//     screen: 'kioskCheckIn',
//     cluster: {
//       id: '123',
//       nama: 'Jantung & Pembuluh Darah',
//       info: 'Lt. 2 | Poli Jantung',
//       rs: 'RS. Mandaya Royal Puri'
//     },
//     delay: {
//       buttonLinearCancelDelay: 2,
//       buttonLinearCancelProgress: 10,
//       buttonLinearCancelAfter: 2,
//       timeOutCancel: 30
//     }
//   }
// }
// const configDeviceType2 = () => {
//   return {
//     screen: 'kioskCheckIn',
//     cluster: {
//       id: '124',
//       nama: 'Penyakit Dalam',
//       info: 'Lt. 1 | Penyakit Dalam',
//       rs: 'RS. Mandaya Karawang'
//     },
//     delay: {
//       buttonLinearCancelDelay: 2,
//       buttonLinearCancelProgress: 10,
//       buttonLinearCancelAfter: 2,
//       timeOutCancel: 30
//     }
//   }
// }

// const configDeviceType3 = () => {
//   return {
//     screen: 'kioskCheckIn',
//     cluster: {
//       id: '125',
//       nama: 'Obygin',
//       info: 'Lt. 11 | Obygin',
//       rs: 'RS. Mandaya Royal Puri'
//     },
//     delay: {
//       buttonLinearCancelDelay: 2,
//       buttonLinearCancelProgress: 10,
//       buttonLinearCancelAfter: 2,
//       timeOutCancel: 30
//     }
//   }
// }

// const configDevice2 = () => {
//   return {
//     screen: 'kioskAntrian',
//     cluster: {
//       id: '123',
//       nama: 'Rawat Jalan Pembayaran Pribadi',
//       floor: 'Lt.11',
//       video: 'http://192.168.40.132/video/index_test_video_internet.txt'
//     },
//     option: [
//       {
//         en: 'Saya <span class="c-hijau">sudah booking</span>',
//         id: '',
//         type: ENUM_SETUP.TYPE_BOOKING,
//         header: 'Pendaftaran',
//         child: [
//           {
//             id: '124',
//             name: 'Pendaftaran Lt.1'
//           },
//           {
//             id: '123',
//             name: 'Asuransi'
//           }
//         ]
//       },
//       {
//         en: 'Saya <span class="not-success">belum booking</span>',
//         id: '',
//         type: ENUM_SETUP.TYPE_WALK_IN,
//         header: 'Pendaftaran',
//         child: [
//           {
//             id: '124',
//             name: 'Pendaftaran Lt.1'
//           }
//         ]
//       },
//       {
//         en: 'Cashier',
//         header: 'Kasir',
//         id: '',
//         type: ENUM_SETUP.TYPE_UNIT,
//         child: [
//           {
//             id: '',
//             name: 'Kasir'
//           }
//         ]
//       },
//       {
//         en: 'Radiologi',
//         header: 'Kasir',
//         id: '',
//         type: ENUM_SETUP.TYPE_UNIT,
//         child: [
//           {
//             id: '',
//             name: 'Radiologi'
//           }
//         ]
//       }
//     ],
//     delay: {
//       buttonLinearCancelDelay: 2,
//       buttonLinearCancelProgress: 10,
//       buttonLinearCancelAfter: 2,
//       timeOutCancel: 30,
//       timeOutToIdle: 60
//     }
//   }
// }

// const configDevice3 = () => {
//   return {
//     screen: 'DSPOLI',
//     cluster: {
//       id: '123',
//       nama: 'Jantung & Pembuluh Darah'
//     }
//   }
// }

// const TYPE_KIOSK = ['kioskCheckIn', 'kioskAntrian']

// const params = new Proxy(new URLSearchParams(window.location.search), {
//   get: (searchParams, prop) => searchParams.get(prop)
// })

export default {
  configDefaultDeviceIdAndToken () {
    return new Promise((resolve, reject) => {
      let config = {}
      try {
        config = JSON.parse(localStorage.getItem('config-kiosk')) ?? {}
      } catch {
        config = {}
      }
      if (!config.deviceId) {
        config.deviceId = new Date().getTime().toString()
      }
      localStorage.setItem('config-kiosk', JSON.stringify(config))
      resolve(config)
      // const config = localStorage.getItem('config-kiosk') ?? {}
      // resolve(config)
      // window.config = new Wrapper((w) => {
      //   const val = w.get()
      //   if (val.type === ENUM_SETUP.WITH_TOKEN) {
      //     resolve(varian2())
      //   } else {
      //     resolve(varian1())
      //   }
      // })
    //   let config = {}
    //   setTimeout(() => {
    //     config = params.variant === '1' ? varian2() : varian1()
    //     return resolve(config)
    //   }, 1000)
    })
  },
  getConfigFromAPI (token, socketId) {
    return new Promise((resolve, reject) => {
      apiRepository.postConfig(socketId, token)
        .then(data => {
          resolve(data)
        }).catch(err => {
          console.log('error kesini')
          reject(new Error(err))
        })
      // window.config = new Wrapper((w) => {
      //   const val = w.get()
      //   if (val.type === ENUM_SETUP.CONFIG_VALID) {
      //     if (val.data === 1) {
      //       resolve({ config: configDevice() })
      //     } else if (val.data === 2) {
      //       resolve({ config: configDeviceType2() })
      //     } else if (val.data === 3) {
      //       resolve({ config: configDeviceType3() })
      //     }
      //   } else if (val.type === ENUM_SETUP.CONFIG_VALID_ANTRIAN) {
      //     resolve({ config: configDevice2() })
      //   } else if (val.type === ENUM_SETUP.CONFIG_INVALID) {
      //     resolve({ config: configDevice3() })
      //   } else if (val.type === ENUM_SETUP.CONFIG_ERROR) {
      //     reject(new Error('error'))
      //   }
      // })
      //   setTimeout(() => {
    //     const configData =
    //         params.config === '3' ? ''
    //           : (params.config === '2' ? configDevice2() : configDevice())
    //     if (configData || window.configSet) {
    //       resolve({ config: configData })
    //     } else {
    //       window.configSet = true
    //       reject(new Error('error'))
    //     }
    //   }, 1500)
    })
  },
  saveConfigToLocalStorage (context) {
    return new Promise((resolve, reject) => {
      // resolve(true)
      localStorage.setItem('config', JSON.stringify(context.data.config))
      // console.log()
      resolve(true)
      // window.config = new Wrapper((w) => {
      //   const val = w.get()
      //   if (val.type === ENUM_SETUP.SAVE_CONFIG_TOLOCAL) {
      //     console.log('masukkk kemari')
      //     localStorage.setItem('config', JSON.stringify(context.data))
      //     resolve(true)
      //   }
      // })
    //   if (context.event.data) {
    //     if (TYPE_KIOSK.includes(context.event.data.type)) {
    //       resolve(true)
    //     } else {
    //       resolve(false)
    //     }
    //   } else {
    //     reject(new Error(false))
    //   }
    })
  },
  saveTokenOnLocalStorage (token) {
    return new Promise((resolve, reject) => {
      const getLocalStorage = JSON.parse(localStorage.getItem('config-kiosk')) ?? {}
      getLocalStorage.token = token
      localStorage.setItem('config-kiosk', JSON.stringify(getLocalStorage))
      resolve(true)
      // console.log('data masuk kemari')
      // window.config2 = new Wrapper((w) => {
      //   const val = w.get()
      //   console.log(val)
      //   if (val.type === ENUM_SETUP.LOCALSTORAGE_DONE) {
      //     resolve(true)
      //   } else if (val.type === ENUM_SETUP.LOCALSTORAGE_ERROR) {
      //     reject(new Error('error'))
      //   }
      // })
    //   if (context) {
    //     localStorage.setItem('hasToken', context)
    //     resolve(true)
    //   } else {
    //     reject(new Error('error'))
    //   }
    })
  },
  howCheckKiosk (eventAll) {
    const data = eventAll.config.option
    const units = eventAll.units
    const checkName = (data1, allUnit) => {
      data1.isCheck = false
      const indexUnit = allUnit.findIndex(_item => parseInt(_item.id) === parseInt(data1.id))
      if (indexUnit >= 0) {
        data1.isCheck = true
        if (!data1.name) {
          data1.name = allUnit[indexUnit].alias ? allUnit[indexUnit].alias : allUnit[indexUnit].nama
        }
      }
      return data1
    }
    data.forEach((_item, index) => {
      const child = _item.child.reduce((prev, item) => {
        const childRef = checkName(item, units)
        if (childRef.isCheck) {
          delete childRef.isCheck
          prev.push(childRef)
        }
        return prev
      }, [])
      data[index].child = child
      if (child.length === 0) {
        data.splice(index, 1)
      }
      // _item.child.forEach((_item2, index2) => {
      //   data[index].child[index2].name =
      // })
    })
    eventAll.config.option = [...data]
    return eventAll
    // const unit = eventAll.units
    // eventAll.option.map(item => {
    //   const child = item.child.filter(item2 => unit.some(last => last.ID === item2.id))
    //   return {
    //     ...item,
    //     child
    //   }
    // })
    // return eventAll
  }
}
