class AllTimeKiosk {
  constructor (data) {
    this.idleTime = data.idleTime ? data.idleTime : 10
    this.logoFooterTimeShow = data.logoFooterTimeShow ? data.logoFooterTimeShow : 2
    this.logoFooterTimeHide = data.logoFooterTimeHide ? data.logoFooterTimeHide : 2
    this.overlayTableCreateAntrian = data.overlayTableCreateAntrian ? data.overlayTableCreateAntrian : 0.5
    this.buttonLinearCancelDelay = data.buttonLinearCancelDelay ? data.buttonLinearCancelDelay : 2
    this.buttonLinearCancelProgress = data.buttonLinearCancelProgress ? data.buttonLinearCancelProgress : 10
    this.buttonLinearCancelAfter = data.buttonLinearCancelAfter ? data.buttonLinearCancelAfter : 2
  }
}

export default AllTimeKiosk
