import DataFake from './dummy-data'
export default {
  getStatusAntrianKasir (code) {
    return new Promise((resolve) => {
      setTimeout(() => {
        switch (code) {
          case '1' :
            resolve(DataFake.dataServiceKasir())
            break
          case '2':
            resolve(DataFake.dataServiceKasir2())
            break
          case '3':
            resolve(DataFake.dataServiceKasir3())
            break
          case '4':
            resolve(DataFake.dataServiceKasir4())
            break
          default:
            resolve(DataFake.dataServiceKasir5())
            break
        }
      }, 3000)
    })
  }
}
