import axios from 'axios'
const BASE_URL = process.env.VUE_APP_API_URL
const API_CHECK_IN = `${BASE_URL}/api/v1/checkin-queue`
const API_ANTRIAN_GENERATE = `${BASE_URL}/api/v1/antrians`
const API_ORDER_LINES = `${BASE_URL}/api/v1/order-lines`
const API_DEVICE_CONFIG = `${BASE_URL}/api/v1/device-config`
const API_MOVE_ANTRIAN = `${BASE_URL}/api/v1/move-queue`
const API_RESERVASIS = `${BASE_URL}/api/v1/reservasis`
const API_CREATE_QUEUE = `${BASE_URL}/api/v1/create-queues`
const API_DATE_SERVER = `${BASE_URL}/api/v1/server-config`
const API_GET_RESERVASI_ORDER = `${BASE_URL}/api/v1/reservasi-orders`
const API_LABEL = `${BASE_URL}/api/v1/labels`
const API_CHANGE_GENERATED_ID = `${BASE_URL}/api/v1/reprint-queue`

export default {
  getAPIDateServer () {
    return axios.get(`${API_DATE_SERVER}`)
  },
  postCreateQueue (body) {
    return axios.post(`${API_CREATE_QUEUE}`, body)
  },
  getReservasis (params) {
    return axios.get(`${API_RESERVASIS}`, { params })
  },
  moveQueueUnit (body) {
    return axios.post(`${API_MOVE_ANTRIAN}`, body)
  },
  checkInQueue (body) {
    return axios.post(`${API_CHECK_IN}`, body)
  },
  getTicketInfo (generatedId) {
    return axios.get(`${API_ANTRIAN_GENERATE}/${generatedId}`)
  },
  getOrderLines (params) {
    return axios.get(`${API_ORDER_LINES}`, { params })
  },
  getReservasiOrder (params) {
    return axios.get(`${API_GET_RESERVASI_ORDER}`, { params })
  },
  getAllLabels () {
    return axios.get(`${API_LABEL}`)
  },
  postChangeGeneratedId (body) {
    return axios.post(`${API_CHANGE_GENERATED_ID}`, body)
  },
  postConfig (socketId, token) {
    return axios.post(`${API_DEVICE_CONFIG}`, {
      deviceSocketId: socketId
    }, {
      headers: {
        token
      }
    })
  }
}
