import moment from 'moment'
import ENUM_SETUP from '../enum/typeSetup'
import apiRepository from '../repositories/apiRepository'
moment.locale('id')
export default {
  async bodyPrint ({ antrian, dokter, cluster, generatedId, createdAt, headerPrint, reprinted }) {
    moment.locale('id')
    const queryParams = process.env.VUE_APP_QUERY_PARAMS_GENERATED.replaceAll('{generatedId}', generatedId)
    const qrCode = `${process.env.VUE_APP_URL_SCAN}${process.env.VUE_APP_PARAMS_GENERATED}?${queryParams}`
    const timeNow = await apiRepository.getAPIDateServer().then(_resp => _resp.data)
    const checkInTime = moment(createdAt).format('dddd, DD MMMM YYYY HH:mm:ss')
    return {
      header: headerPrint ?? ENUM_SETUP.DEFAULT_HEADER_PRINT,
      checkInTime,
      antrian,
      ...(cluster ? { cluster } : {}),
      ...(dokter && dokter.length ? { dokter } : {}),
      // qrCode: `https://caredr.org?generatedId=${generatedId}`,
      qrCode,
      time: moment(timeNow.serverTime).format('DD MMM YYYY HH:mm:ss'),
      ...(reprinted ? { reprinted: true } : {})
    }
  },
  getListBookingMapper (list) {
    const newList = list.map(item => {
      // const poli = item.dokterUnit && item.dokterUnit.klinikDokters && item.dokterUnit.klinikDokters.length > 0 && item.dokterUnit.klinikDokters[0].name ? item.dokterUnit.klinikDokters[0].name : ''
      const poli = item.dokterUnit && item.dokterUnit.speciality ? item.dokterUnit.speciality : '-'
      const time = item.jamMulai ? item.jamMulai.slice(0, 5) : 'EXTRA SLOT'
      const dateTime = moment(item.tanggalKunjungan).format('dddd, DD MMMM YYYY')
      return {
        id: item.dokterId,
        name: item.dokterUnit.dokterName,
        poli,
        time,
        timeWithDate: `${dateTime} ${time} WIB`,
        namaPasien: item.namaPasien,
        idPasien: item.id
      }
    })
    return newList
  },
  getListPasienMapper (list) {
    return list.reduce((prev, next) => {
      const index = prev.findIndex(item => item.namaPasien.toLocaleLowerCase() === next.namaPasien.toLocaleLowerCase() && item.tanggalLahir === next.tanggalLahir)
      if (index >= 0) {
        prev[index].list.push(next)
      } else {
        prev.push({
          id: next.id,
          namaPasien: next.namaPasien,
          tanggalLahir: next.tanggalLahir,
          list: [next]
        })
      }
      return prev
    }, [])
  },
  mapperPostionKey (list, selected) {
    return {
      id: list.index,
      numberAntrian: list.antrian && list.antrian.ticketNo ? list.antrian.ticketNo : '',
      time: list.lastCheckinTime ? moment(list.lastCheckinTime).format('HH:mm') : '-',
      status: list.status,
      ...(list.type ? { type: list.type } : {}),
      ticketNo: list.antrian && list.antrian.ticketNo ? list.antrian.ticketNo : '',
      ...(list.sessionStart ? { sessionStart: list.sessionStart.substring(0, 5) } : {}),
      checkIn: list.lastCheckinTime ? moment(list.lastCheckinTime).format('HH:mm') : '-',
      ...(selected ? { selected: true } : {})
    }
  },
  mapperPositionMultiple (listQueue, listTicket, objData, listBadge = []) {
    const sortWithMapperListQueue = listQueue.map(_item => {
      const labelBadge = this.setBadgeInItem(_item)
      let data = labelBadge.status ?? _item.status
      const detailBadge = listBadge.find(_item => _item.name === data)
      if (detailBadge && detailBadge.displayText) {
        data = detailBadge.displayText
      }
      return {
        ..._item,
        status: data,
        momentLastCheckIn: parseInt(moment(_item.lastCheckinTime).format('x')),
        sortTime: _item.sortTime ? parseInt(moment(_item.sortTime).format('x')) : undefined,
        updatedAt: parseInt(moment(_item.UpdatedAt).format('x'))
        // momentLastCheckInTime: _item.sortTime ? moment(_item.sortTime).format('x') : moment(_item.lastCheckinTime).format('x')
      }
    })
    const sortListQueue = sortWithMapperListQueue.sort((itemA, itemB) => {
      const sortTime = (itemA.sortTime ?? itemA.momentLastCheckIn) - (itemB.sortTime ?? itemB.momentLastCheckIn)
      const sortUpdate = itemA.updatedAt - itemB.updatedAt
      return sortTime !== 0
        ? sortTime
        : sortUpdate
    }).map((x, index) => {
      return {
        index: index + 1,
        ...x
      }
    })
    const numberTicket = listTicket[listTicket.length - 1]
    const indexCenter = sortListQueue.findIndex(item => item.antrian && item.antrian.ticketNo === numberTicket)
    const indexList = sortListQueue.length > 4 ? sortListQueue.length - 2 : indexCenter
    const queueList = []
    const indexRow = indexList <= 2 ? 4 - indexList : 2
    for (let i = indexList - 2; i <= indexList + indexRow; i++) {
      if (i >= 0 && sortListQueue.length > i) {
        const isSelected = listTicket.some(_item => _item === sortListQueue[i].antrian.ticketNo)
        queueList.push(this.mapperPostionKey(sortListQueue[i], isSelected))
      }
    }
    return {
      ticket: listTicket.slice().join(', '),
      list: queueList,
      objectShow: objData
    }
  },
  mapperPosition (listQueue, ticketNumber, objData, listBadge = []) {
    // console.log('listttt', listQueue)
    const sortWithMapperListQueue = listQueue.map(_item => {
      const labelBadge = this.setBadgeInItem(_item)
      let data = labelBadge.status ?? _item.status
      const detailBadge = listBadge.find(_item => _item.name === data)
      if (detailBadge && detailBadge.displayText) {
        data = detailBadge.displayText
      }
      return {
        ..._item,
        status: data,
        momentLastCheckIn: parseInt(moment(_item.lastCheckinTime).format('x')),
        sortTime: _item.sortTime ? parseInt(moment(_item.sortTime).format('x')) : undefined,
        updatedAt: parseInt(moment(_item.UpdatedAt).format('x'))
        // momentLastCheckInTime: _item.sortTime ? moment(_item.sortTime).format('x') : moment(_item.lastCheckinTime).format('x')
      }
    })
    const sortListQueue = sortWithMapperListQueue.sort((itemA, itemB) => {
      const sortTime = (itemA.sortTime ?? itemA.momentLastCheckIn) - (itemB.sortTime ?? itemB.momentLastCheckIn)
      const sortUpdate = itemA.updatedAt - itemB.updatedAt
      return sortTime !== 0
        ? sortTime
        : sortUpdate
    }).map((x, index) => {
      return {
        index: index + 1,
        ...x
      }
    })

    const indexList = sortListQueue.findIndex(item => item.antrian && item.antrian.ticketNo === ticketNumber)
    const queueList = []
    const indexRow = indexList <= 2 ? 4 - indexList : 2
    for (let i = indexList - 2; i <= indexList + indexRow; i++) {
      if (i >= 0 && sortListQueue.length > i) {
        queueList.push(this.mapperPostionKey(sortListQueue[i], indexList === i))
      }
    }
    return {
      ticket: ticketNumber,
      list: queueList,
      objectShow: objData
    }
  },
  mapperDataPasienPendaftaran (data) {
    // const mapData = data && data.list ? data.list[0] : {}
    return {
      ...(data.idPasien ? { id: data.idPasien } : {}),
      ...(data.email ? { email: data.email } : {}),
      ...(data.nama ? { nama: data.nama } : {}),
      ...(data.namaPasien ? { nama: data.namaPasien } : {}),
      ...(data.nomorMedicalRecord ? { nomorMedicalRecord: data.nomorMedicalRecord } : {}),
      ...(data.ssn ? { ssn: data.ssn } : {}),
      ...(data.tanggalKunjungan ? { tanggalKunjungan: data.tanggalKunjungan } : {}),
      ...(data.tanggalLahir ? { tanggalLahir: moment(data.tanggalLahir).format('YYYY-MM-DD') + 'T00:00:00Z' } : {}),
      ...(data.telp ? { telp: data.telp } : {}),
      ...(data.passport ? { passport: data.passport } : {}),
      ...(data.gender ? { gender: data.gender } : {}),
      ...(data.patientId ? { patientId: data.patientId } : {})

    }
  },
  mapperDokter (data) {
    return data.map(_item => {
      const sessionStart = _item.sessionStart ? _item.sessionStart.slice(0, 5) : ''
      const sessionEnd = _item.sessionStart ? _item.sessionEnd.slice(0, 5) : ''
      return {
        nama: _item.unit.name,
        spesialis: _item.unit.type === 'DOKTER' ? _item.unit.Dokter.speciality : '-',
        time: sessionStart ? `${sessionStart}-${sessionEnd}` : '-'
      }
    })
  },
  choisePasien (list, index = 0) {
    if (list.length === 0) return {}
    const dataBooking = list.reduce((prev, item) => {
      if (index === 0) {
        if (item.mrn) {
          prev.push(item)
        }
      } else if (index === 1) {
        if (item.patientId) {
          prev.push(item)
        }
      } else if (index === 2) {
        if (prev.length === 0) {
          prev.push(item)
        } else {
          const updateTimeLast = moment(prev[0].CreatedAt).format('x')
          const updateTimeItem = moment(item.createdAt).format('x')
          if (updateTimeItem > updateTimeLast) {
            prev.splice(0, 1, item)
          }
        }
      }
      return prev
    }, [])
    if (index === 0 && dataBooking.length > 1) return this.choisePasien(dataBooking, 1)
    else if (index === 0 && dataBooking.length === 0) return this.choisePasien(list, 1)
    else if (index === 1 && dataBooking.length > 1) return this.choisePasien(dataBooking, 2)
    else if (index === 1 && dataBooking.length === 0) return this.choisePasien(list, 2)
    else return this.mapperDataPasienPendaftaran(dataBooking[0])
    // if (dataBooking.length < 2 && index > 0) {
    //   if (dataBooking.length === 0) return {}
    //   const dataPasien = this.mapperDataPasienPendaftaran(dataBooking[0])
    //   return dataPasien
    // } else if (dataBooking.length === 0 && index === 0) {
    //   return this.choisePasien(list, 1)
    // } else {
    //   return this.choisePasien(dataBooking, index + 1)
    // }
  },
  setBadgeInItem (item) {
    const labelBadge = []
    if (item.antrian && item.antrian.visitBadges) {
      labelBadge.push(...item.antrian.visitBadges)
    }
    if (item.antrian && item.antrian.patient && item.antrian.patient.patientLabels) {
      labelBadge.push(...item.antrian.patient.patientLabels)
    }
    const dataShowTV = labelBadge.reduce((prev, item) => {
      if (item.isShowTv) {
        if (prev.bobot < item.bobot) {
          prev.bobot = item.bobot
          prev.displayText = item.displayText ?? item.name
        } else if (prev.bobot === item.bobot && prev.bobotType < item.bobotType) {
          prev.bobotType = item.bobotType
          prev.displayText = item.displayText ?? item.name
        }
      }
      return prev
    }, { isPriority: false, bobot: 0, bobotType: 0, displayText: '' })
    return {
      ...(dataShowTV.displayText ? { status: dataShowTV.displayText } : {})
    }
  },
  mapperLabel (data) {
    if (Array.isArray(data)) {
      return data.map(_item => {
        return {
          enums: _item.callerEnum,
          name: _item.name,
          displayText: _item.displayText ? _item.displayText : ''
        }
      })
    }
    return []
  },
  getDataNameClusterFloor (data) {
    if (data.unit && data.unit.rooms) {
      const rooms = data.unit.rooms[0]
      return `${rooms.clusterNama} ${rooms.clusterLantai}`
    } else if (data.unit && data.unit.Dokter) {
      const dokter = data.unit.Dokter
      if (dokter.kliniks && dokter.kliniks.length > 0) {
        const kliniks = dokter.kliniks[0]
        return `${kliniks.clusterNama ?? ''} ${kliniks.clusterLantai ?? '-'}`
      }
    }
    return '-'
  },
  mapperPrintTicket (data) {
    const dokterList = data.orderLines.filter(_item => _item.unit && _item.unit.type === ENUM_SETUP.DOKTER && _item.status !== ENUM_SETUP.REMOVE)
    const serviceList = data.orderLines.filter(_item => _item.unit && [ENUM_SETUP.RADIOLOGY, ENUM_SETUP.MEDICAL_REHAB, ENUM_SETUP.NUKLIR].some(_type => _type === _item.unit.type) && _item.status !== ENUM_SETUP.REMOVE)
    const listOrder = [...dokterList, ...serviceList]
    const timeNow = moment(new Date()).format('dddd, DD MMMM YYYY')
    const dataPrintDokter = listOrder.length > 0 ? listOrder.map(_item => {
      return {
        name: _item.unit.name,
        poli: this.getDataNameClusterFloor(_item),
        timeWithDate: _item.sessionStart ? `${timeNow} ${_item.sessionStart.slice(0, 5)}` : `${timeNow} EXTRA SLOT`
      }
    }) : []
    return {
      generatedId: data.generatedId,
      antrian: data.ticketNo,
      createdAt: data.CreatedAt,
      dokter: dataPrintDokter,
      reprinted: true
    }
  }
}
