import ID from '@/lang/id.json'
import EN from '@/lang/en.json'
export default {
  namespaced: true,
  state: {
    lang: localStorage.Language === 'id' ? ID : EN
  },
  mutations: {
    SET_CONFIG_LANG (state, res) {
      state.lang = res
    }
  },
  actions: {
    async CHANGE_LANGUAGE ({ commit, dispatch }, lang) {
      if (lang === 'id') {
        commit('SET_CONFIG_LANG', ID)
      } else {
        commit('SET_CONFIG_LANG', EN)
      }
      dispatch('DocumentHandler/SET_CARD_COMPONENTS', '', { root: true })
    }
  }
}
