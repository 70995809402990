import store from '@/store'

export default {
  detectLanguage (val) {
    if (store.state.Language.lang[val]) {
      return store.state.Language.lang[val]
    } else {
      return val
    }
  }
}
