import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/main.css'
import '@/assets/css/kioskCheckIn.css'
// import Toaster from '@meforma/vue-toaster'
import './registerServiceWorker'
// import mitt from 'mitt'
// const emitter = mitt()
import emitter from '@/services/mitt'
import lang from '@/services/Language'

import { io } from 'socket.io-client'
import MoreFunction from '@/helpers/moreFunction'
import { inspect } from '@xstate/inspect'

import '@fontsource/montserrat'
import '@fontsource/montserrat/100.css'
import '@fontsource/montserrat/200.css'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/montserrat/800.css'
import '@fontsource/inconsolata'
import '@fontsource/inconsolata/500.css'
import '@fontsource/inconsolata/600.css'
import '@fontsource/inconsolata/700.css'
window.socket = io(process.env.VUE_APP_SOCKET_URL)
if (process.env.VUE_APP_DEVELOPMENT) {
  inspect({
    iframe: false
  })
}

const app = createApp(App)
  .use(store)
  .use(router)
app.config.globalProperties.emitter = emitter
app.config.globalProperties.$l = lang
app.config.globalProperties.$filters = {
  asteriks (data) {
    const moreFunction = new MoreFunction()
    return moreFunction.asteriskNamaConvert(data)
  }
}
app.mount('#app')
