import { createRouter, createWebHistory } from 'vue-router'
// import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/setup',
    name: 'Setup',
    component: () => import('../views/page/Setup.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/Setting.vue')
  },
  {
    path: '/kiosk-checkin',
    name: 'kioskCheckIn',
    component: () => import('../views/checkInKiosk/InitProvider.vue')
  },
  {
    path: '/kiosk-antrian',
    component: () => import('../views/page/Init.vue'),
    children: [
      {
        path: '',
        name: 'kioskAntrian',
        component: () => import('../views/Home.vue')
      },
      {
        path: 'menu',
        name: 'Menu',
        component: () => import('../views/MenuProvider.vue')
      },
      {
        path: 'loading',
        name: 'loading',
        component: () => import('../views/page/Loading.vue')
      },
      {
        path: 'input-otp',
        name: 'inputKeypad',
        component: () => import('../views/ShowInputKeypad.vue')
      },
      {
        path: 'show-ticket',
        name: 'showTicket',
        component: () => import('../views/ShowTicket.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () =>
      import('../views/page/Setup.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.name === 'Setup' && !localStorage.configPoli) {
//     next()
//   } else if (!localStorage.configPoli) {
//     next({ path: '/setup' })
//   } else {
//     next()
//   }
// })

export default router
