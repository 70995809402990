export default class Wrapper {
  constructor (callback) {
    this.callback = callback
  }

  set (v) {
    this.value = v
    this.callback(this)
  }

  get () {
    return this.value
  }
}
