export default {
  namespaced: true,
  state: {
    ticket: {}
  },
  mutations: {
    SET_TICKET (state, res) {
      state.ticket = res
    }
  },
  actions: {

  }
}
