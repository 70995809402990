/* eslint-disable no-dupe-keys */
import ENUM_SETUP from '../enum/typeSetup'
import Wrapper from './Wrapper'
// import axios from 'axios'
import mapperAntrian from '@/mappers/KioskAntrianMapper'
import moment from 'moment'
import apiRepository from './apiRepository'
const TICKET_BOOKING = {
  type: ENUM_SETUP.TYPE_BOOKING,
  ticket: {
    code: 'QRRST'
  }
}

const TICKET_ANTRIAN = {
  type: ENUM_SETUP.TYPE_UNIT,
  ticket: {
    code: '0020'
  }
}

const SUCCESS_TABLE = {
  ticket: '0020',
  list: [
    {
      id: 14,
      numberAntrian: '0019',
      status: 'Waiting'
    },
    {
      id: 15,
      numberAntrian: '0030',
      status: 'Waiting'
    },
    {
      id: 16,
      numberAntrian: '0020',
      status: 'Waiting',
      selected: true
    }
  ]
}

const SUCCESS_TABLE_2 = {
  ticket: '0020',
  list: [
    {
      id: 15,
      numberAntrian: '0016',
      time: '14:52',
      status: 'Waiting'
    },
    {
      id: 16,
      numberAntrian: '0017',
      time: '14:54',
      status: 'Waiting'
    },
    {
      id: 17,
      numberAntrian: '0020',
      time: '14:59',
      status: 'Waiting',
      selected: true
    },
    {
      id: 18,
      numberAntrian: '0006',
      time: '15:00',
      status: 'Waiting'
    },
    {
      id: 19,
      numberAntrian: '0012',
      time: '15:12',
      status: 'Waiting'
    }
  ]
}

const TICKET_BOOKING_1 = {
  listDokter: [
    {
      id: 1,
      name: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp.KFR',
      poli: 'Rehabilitasi Medik',
      time: '2022 10:30'
    },
    {
      id: 2,
      name: 'dr. Tjhin Sun Fu, Sp.B, M.BioMed',
      poli: 'Bedah umum',
      time: '13:30'
    },
    {
      id: 3,
      name: 'dr. Nancy Indah Lestari, SpPD',
      poli: 'Penyakit Dalam',
      time: '15:30'
    }
  ]
}

const TICKET_BOOKING_2 = {
  listDokter: [{
    id: 1,
    name: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp.KFR',
    poli: 'Rehabilitasi Medik',
    time: '10:30'
  }]
}

const PASIEN_PHONE = {
  listPasien: [
    {
      id: 123,
      nama: 'HEN*** GUNA***',
      dokter: [
        {
          nama: 'dr. Andi Sapriadi Junusi, SpPD',
          klinik: 'Penyakit Dalam',
          time: '15:30'
        },
        {
          nama: 'dr. Wawan Setiawan, Sp.B, M.Biomed',
          klinik: 'Bedah Umum',
          time: '15:30'
        }
      ]
    },
    {
      id: 124,
      nama: 'ZA** IRSA****',
      dokter: [
        {
          nama: 'dr. Wahyu Andi Bayu, Sp.B, M.Biomed',
          klinik: 'Bedah Umum',
          time: '15:30'
        }
      ]
    }
  ]
}

const PASIEN_PHONE_2 = {
  listPasien: [
    {
      id: 125,
      nama: 'HEN*** GUNA***',
      listDokter: [
        {
          id: 123,
          nama: 'dr. Andi Sapriadi Junusi, SpPD',
          poli: 'Penyakit Dalam',
          time: '15:30'
        },
        {
          id: 124,
          nama: 'dr. Wawan Setiawan, Sp.B, M.Biomed',
          poli: 'Bedah Umum',
          time: '15:30'
        }
      ]
    }
  ]
}

const ANTRIAN_1 = {
  listDokter: []
}

const ANTRIAN_2 = {
  listDokter: [
    {
      nama: 'dr. Tjhin Sun Fu, Sp.B, M.Biomed',
      spesialis: 'Bedah Umum',
      time: '13:30',
      isWaiting: false,
      isFinish: false
    },
    {
      nama: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp...',
      spesialis: 'Rehabilitasi Medik',
      time: '15:30',
      isWaiting: false,
      isFinish: false
    }
  ]
}

const ANTRIAN_3 = {
  listDokter: [
    {
      nama: 'dr. Tjhin Sun Fu, Sp.B, M.Biomed',
      spesialis: 'Bedah Umum',
      time: '13:30',
      isWaiting: true,
      isFinish: false
    }
  ]
}

const ANTRIAN_4 = {
  listDokter: [
    {
      nama: 'dr. Tjhin Sun Fu, Sp.B, M.Biomed',
      spesialis: 'Bedah Umum',
      time: '13:30',
      isWaiting: false,
      isFinish: false
    },
    {
      nama: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp...',
      spesialis: 'Rehabilitasi Medik',
      time: '15:30',
      isWaiting: false,
      isFinish: false
    },
    {
      nama: 'dr. Nancy Indah Lestari, SpPD',
      spesialis: 'Penyakit Dalam',
      time: '10:30',
      isWaiting: true,
      isFinish: false
    }
  ]
}

const ANTRIAN_5 = {
  listDokter: [
    {
      nama: 'dr. Tjhin Sun Fu, Sp.B, M.Biomed',
      spesialis: 'Bedah Umum',
      time: '13:30',
      isWaiting: false,
      isFinish: false,
      doubleCheck: true
    },
    {
      nama: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp...',
      spesialis: 'Rehabilitasi Medik',
      time: '15:30',
      isWaiting: false,
      isFinish: false
    }
  ]
}

const ANTRIAN_6 = {
  listDokter: [
    {
      nama: 'dr. Tjhin Sun Fu, Sp.B, M.Biomed',
      spesialis: 'Bedah Umum',
      time: '13:30',
      isWaiting: false,
      isFinish: false,
      isNotRegist: true
    }
  ]
}

const CONFIG_1 = {
  config: {
    screen: 'kioskAntrian',
    cluster: {
      id: '123',
      nama: 'Rawat Jalan Pembayaran Pribadi',
      floor: 'Lt.11',
      video: 'http://192.168.40.132/video/index_test_video_internet.txt'
    },
    option: [
      {
        en: 'Saya <span class="c-hijau">sudah booking</span>',
        id: '',
        type: ENUM_SETUP.TYPE_BOOKING,
        header: 'Pendaftaran',
        child: [
          {
            id: '124',
            name: 'Pendaftaran Lt.1'
          },
          {
            id: '123',
            name: 'Asuransi'
          }
        ]
      },
      {
        en: 'Cashier',
        header: 'Kasir',
        id: '',
        type: ENUM_SETUP.TYPE_UNIT,
        child: [
          {
            id: '',
            name: 'Kasir'
          }
        ]
      },
      {
        en: 'Radiologi',
        header: 'Radiologi',
        id: '',
        type: ENUM_SETUP.TYPE_UNIT,
        child: [
          {
            id: '',
            name: 'Radiologi'
          }
        ]
      }
    ],
    delay: {
      buttonLinearCancelDelay: 2,
      buttonLinearCancelProgress: 10,
      buttonLinearCancelAfter: 2,
      timeOutCancel: 30,
      timeOutToIdle: 60
    }
  }
}

const CONFIG_2 = {
  config: {
    screen: 'kioskAntrian',
    cluster: {
      id: '123',
      nama: 'Rawat Jalan Pembayaran Pribadi',
      floor: 'Lt.11',
      video: 'http://192.168.40.132/video/index_test_video_internet2.txt'
    },
    option: [
      {
        en: 'Saya <span class="c-hijau">sudah booking</span>',
        id: '',
        type: ENUM_SETUP.TYPE_BOOKING,
        header: 'Pendaftaran',
        child: [
          {
            id: '123',
            name: 'Asuransi'
          }
        ]
      },
      {
        en: 'Saya <span class="not-success">belum booking</span>',
        id: '',
        type: ENUM_SETUP.TYPE_WALK_IN,
        header: 'Pendaftaran',
        child: [
          {
            id: '123',
            name: 'Asuransi'
          }
        ]
      },
      {
        en: 'Cashier',
        header: 'Kasir',
        id: '',
        type: ENUM_SETUP.TYPE_UNIT,
        child: [
          {
            id: '',
            name: 'Kasir'
          }
        ]
      }
    ],
    delay: {
      buttonLinearCancelDelay: 2,
      buttonLinearCancelProgress: 10,
      buttonLinearCancelAfter: 2,
      timeOutCancel: 30,
      timeOutToIdle: 60
    }
  }
}

const CONFIG_3 = {
  config: {
    screen: 'kioskAntrian',
    cluster: {
      id: '123',
      nama: 'Rawat Jalan Pembayaran Pribadi',
      floor: 'Lt.11',
      video: 'http://192.168.40.132/video/index_test_video_internet3.txt'
    },
    option: [
      {
        en: 'Saya <span class="c-hijau">sudah booking</span>',
        id: '',
        type: ENUM_SETUP.TYPE_BOOKING,
        header: 'Pendaftaran',
        child: [
          {
            id: '123',
            name: 'Asuransi'
          }
        ]
      },
      {
        en: 'Saya <span class="not-success">belum booking</span>',
        id: '',
        type: ENUM_SETUP.TYPE_WALK_IN,
        header: 'Pendaftaran',
        child: [
          {
            id: '123',
            name: 'Asuransi'
          }
        ]
      },
      {
        en: 'Cashier',
        header: 'Kasir',
        id: '',
        type: ENUM_SETUP.TYPE_UNIT,
        child: [
          {
            id: '',
            name: 'Kasir'
          }
        ]
      },
      {
        en: 'Radiologi',
        header: 'Radiologi',
        id: '',
        type: ENUM_SETUP.TYPE_UNIT,
        child: [
          {
            id: '',
            name: 'Radiologi'
          }
        ]
      }
    ],
    delay: {
      buttonLinearCancelDelay: 2,
      buttonLinearCancelProgress: 10,
      buttonLinearCancelAfter: 2,
      timeOutCancel: 30,
      timeOutToIdle: 60
    }
  }
}

const data = {
  TICKET_BOOKING,
  TICKET_ANTRIAN,
  SUCCESS_TABLE,
  SUCCESS_TABLE_2,
  TICKET_BOOKING_1,
  TICKET_BOOKING_2,
  PASIEN_PHONE,
  PASIEN_PHONE_2,
  ANTRIAN_1,
  ANTRIAN_2,
  ANTRIAN_3,
  ANTRIAN_4,
  ANTRIAN_5,
  ANTRIAN_6,
  CONFIG_1,
  CONFIG_2,
  CONFIG_3
}

export default {
  get (name) {
    return data[name]
  },
  objPendaftaran () {
    return [
      'id',
      'ticketNo',
      'status'
    ]
  },
  objToUnit () {
    return [
      'id',
      'ticketNo',
      'checkIn',
      'status'
    ]
  },
  checkValidationTicket () {
    return new Promise(() => {
      window.checkIn = new Wrapper((w) => {
        const val = w.get()
        if (val.type === ENUM_SETUP.VALID_TICKET) {
        //   const detail = checkURL(context.state.link)
        //   resolve(validTicket({ id: detail.ticket, isAdmin: detail.admin }))
        } else if (val.type === ENUM_SETUP.INVALID_TICKET) {
        //   reject(new Error('error'))
        }
      })
    })
  },
  sendToAPI ({ unitId, reservasiPatient, reservasiIds }) {
    // console.log(reservasiPatient)
    const body = {
      connId: window.socket.id,
      unitId,
      ...(reservasiPatient.nama ? { reservasiPatient } : {}),
      ...(reservasiIds ? { reservasiIds } : {})
    }
    return new Promise((resolve) => {
      // axios.post(`${process.env.VUE_APP_API_URL}/api/v1/create-queues`, {
      //   ...body
      // })
      apiRepository.postCreateQueue(body)
        .then(data => {
          resolve(data)
        }).catch(err => new Error(err))
      // window.config = new Wrapper((w) => {
      //   const val = w.get()
      //   if (val.type === ENUM_SETUP.INVALID_TICKET) {
      //     reject(new Error('error'))
      //   }
      // })
    })
  },
  getInfoTicket ({ key, isCode }) {
    return new Promise((resolve, reject) => {
      const nowDate = moment().format('YYYY-MM-DD')
      const params = {
        ...(key.toString().length === 32 ? { 'generatedId.equals': key } : (isCode ? { 'kodeBooking.equals': key } : { 'noTelp.equals': key })),
        'tanggalKunjungan.equals': nowDate
      }
      // axios.get(`${process.env.VUE_APP_API_URL}/api/v1/reservasis`, {
      // })
      apiRepository.getReservasis(params)
        .then(_resp => {
          const list = _resp.data.filter(_item => !(_item.orderline && _item.orderline.orderLineId) && _item.reservasiStatus !== 'CANCEL')
          if (list.length > 0) {
            resolve(list)
          } else {
            reject(new Error('NOT DATA'))
          }
        }).catch(err => reject(new Error(err)))
    })
  },
  getOrderLines (unitId, ticketNumber, objColumn, listBadge = []) {
    return new Promise((resolve, reject) => {
      var params = new URLSearchParams()
      params.append('unitId.In', unitId)
      params.append('status.In', 'WAITING')
      params.append('status.In', 'SKIP')
      params.append('status.In', 'CALLING')
      params.append('status.In', 'SERVING')
      params.append('status.In', 'HOLD')
      params.append('status.In', 'PREPARE')
      params.append('status.In', 'READY')
      // axios.get(`${process.env.VUE_APP_API_URL}/api/v1/order-lines`, {
      //   params
      // })
      apiRepository.getOrderLines(params)
        .then(_resp => {
        // console.log('!!!!!!!', _resp, ticketNumber)
          let positionOrder = {}
          if (Array.isArray(ticketNumber)) {
            positionOrder = mapperAntrian.mapperPositionMultiple(_resp.data, ticketNumber, objColumn, listBadge)
          } else {
            positionOrder = mapperAntrian.mapperPosition(_resp.data, ticketNumber, objColumn, listBadge)
          }
          if (positionOrder && positionOrder.list.length > 0) {
            resolve(positionOrder)
          } else {
            reject(new Error('NOT DATA'))
          }
        }).catch(err => reject(new Error(err)))
    })
  },
  getAntrianWithGenerate (generatedId) {
    return new Promise((resolve, reject) => {
      // axios.get(`${process.env.VUE_APP_API_URL}/api/v1/antrians/${generatedId}`)
      apiRepository.getTicketInfo(generatedId)
        .then(_resp => {
          resolve(_resp.data)
        })
        .catch(err => {
          reject(err.response)
        })
    })
  },
  checkInQueue ({ unit, generatedId }) {
    // const body = {
    //   connId: window.socket.id,
    //   generatedId,
    //   unitCheckIns: [
    //     {
    //       isLastTime: true,
    //       isShowTime: true,
    //       unitId: unit
    //     }
    //   ]
    // }
    const body = {
      connId: window.socket.id,
      generatedId,
      nextUnitIds: [unit]
    }
    return new Promise((resolve, reject) => {
      apiRepository.moveQueueUnit(body)
        .then(_resp => {
          resolve(_resp)
        })
        .catch(err => {
          reject(err.response)
        })
    })
  },
  getAllLabels () {
    return new Promise(resolve => {
      apiRepository.getAllLabels()
        .then(_resp => _resp.data)
        .then(_data => {
          const mapData = mapperAntrian.mapperLabel(_data)
          resolve(mapData)
        })
    })
  },
  regenerateTicket (generatedId) {
    const bodyQueue = {
      connId: window.socket.id,
      generatedId
    }
    return apiRepository.postChangeGeneratedId(bodyQueue)
  }
}
