// import Wrapper from './Wrapper'
// import ENUM_SETUP from '../enum/typeSetup'

// const params = new Proxy(new URLSearchParams(window.location.search), {
//   get: (searchParams, prop) => searchParams.get(prop)
// })
// const validSocketConnection = () => {
//   return {
//     socketId: Math.floor(Math.random() * 10000000000)
//   }
// }

// const closeConnectionTrue = () => {
//   return {
//     isAlreadyClose: true
//   }
// }
// const closeConnectionFalse = () => {
//   return {
//     isAlreadyClose: 2
//   }
// }
export default {
  openSocketConnection () {
    return new Promise((resolve, reject) => {
      var socketConnection = window.socket.disconnect().connect()
      socketConnection.on('connect', function (_event) {
        // console.log(_event, '<----------')
        resolve({
          socketId: window.socket.id
        })
        // const sessionID = socketConnection.socket.sessionid
        // console.log(socketConnection)
        // resolve(sessionID)
      })
      // window.config = new Wrapper((w) => {
      //   const val = w.get()
      //   if (val.type === ENUM_SETUP.SOCKET_CONNECT) {
      //     resolve(validSocketConnection())
      //   } else if (val.type === ENUM_SETUP.SOCKET_DISCONNECT) {
      //     reject(new Error('error'))
      //   }
      // })
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     const variant = params.socket !== '1' || window.locationChange ? validSocketConnection() : ''
    //     if (variant) {
    //       resolve(variant)
    //     } else {
    //       window.locationChange = true
    //       reject(new Error('error'))
    //     }
    //   }, 2000)
    })
  },
  openSocketConnectionWithToken () {
    return new Promise((resolve, reject) => {
      resolve(true)
      // window.config = new Wrapper((w) => {
      //   const val = w.get()
      //   if (val.type === ENUM_SETUP.SOCKET_CONNECT) {
      //     resolve(validSocketConnection())
      //   } else if (val.type === ENUM_SETUP.SOCKET_DISCONNECT) {
      //     reject(new Error('error'))
      //   }
      // })
    //   setTimeout(() => {
    //     const variant = params.socket !== '1' || window.locationChange1 ? validSocketConnection() : ''
    //     if (variant) {
    //       resolve(variant)
    //     } else {
    //       window.locationChange1 = true
    //       reject(new Error('error'))
    //     }
    //   }, 5000)
    })
  },
  closeConnectionSocket (token, deviceId) {
    return new Promise((resolve, reject) => {
      try {
        window.socket.io.opts.query = `token=${token}&deviceId=${deviceId}`
      } catch (err) {
        console.error(err)
      }
      window.socket.connect()
      resolve(true)
      // window.config3 = new Wrapper((w) => {
      //   const val = w.get()
      //   if (val.type === ENUM_SETUP.CONNECTION_CLOSE) {
      //     resolve(true)
      //   } else if (val.type === ENUM_SETUP.CONNECTION_CLOSE_ERROR) {
      //     reject(new Error(closeConnectionTrue()))
      //   } else if (val.type === ENUM_SETUP.CONNECTION_CLOSE_RETRY) {
      //     reject(new Error(closeConnectionFalse()))
      //   }
      // })
    //   setTimeout(() => {
    //     const variant = params.closeConnection === '2'
    //       ? closeConnectionTrue() : (params.closeConnection === '3' ? closeConnectionFalse() : '')
    //     if (variant.isConnect && !window.closeConnection) {
    //       window.closeConnection = true
    //       reject(new Error(variant))
    //     } else {
    //       resolve(true)
    //     }
    //   }, 2000)
    })
  }
}
