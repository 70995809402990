const MAIN_KIOSK = {
  WITH_TOKEN: 'WITH_TOKEN',
  NO_TOKEN: 'NO_TOKEN',
  SOCKET_CONNECT: 'SOCKET_CONNECT',
  SOCKET_DISCONNECT: 'SOCKET_DISCONNECT',
  LOCALSTORAGE_DONE: 'LOCALSTORAGE_DONE',
  LOCALSTORAGE_ERROR: 'LOCALSTORAGE_ERROR',
  CONNECTION_CLOSE: 'CONNECTION_CLOSE',
  CONNECTION_CLOSE_ERROR: 'CONNECTION_CLOSE_ERROR',
  CONNECTION_CLOSE_RETRY: 'CONNECTION_CLOSE_RETRY',
  CONFIG_VALID: 'CONFIG_VALID',
  CONFIG_VALID_ANTRIAN: 'CONFIG_VALID_ANTRIAN',
  CONFIG_INVALID: 'CONFIG_INVALID',
  CONFIG_ERROR: 'CONFIG_ERROR',
  SAVE_CONFIG_TOLOCAL: 'SAVE_CONFIG_TOLOCAL',
  DEFAULT_HEADER_PRINT: 'RS. MANDAYA ROYAL PURI'
}

const KIOSK_CHECKIN = {
  SCAN_TICKET: 'SCAN_TICKET',
  VALID_TICKET: 'VALID_TICKET',
  INVALID_TICKET: 'INVALID_TICKET',
  ERROR_GET_TICKET: 'ERROR_GET_TICKET',
  SUCCESS_TICKET: 'SUCCESS_TICKET',
  FAILED_TICKET: 'FAILED_TICKET'
}

const PAGE_KIOSK_CHECKIN = {
  PAGE_IDLE_CLUSTER: 'PAGE_IDLE_CLUSTER',
  PAGE_LOADING_CLUSTER: 'PAGE_LOADING_CLUSTER',
  PAGE_ERROR_CLUSTER: 'PAGE_ERROR_CLUSTER',
  PAGE_WRONG_CHECK_IN: 'PAGE_WRONG_CHECK_IN',
  PAGE_SINGLE_CHECKIN: 'PAGE_SINGLE_CHECKIN',
  PAGE_MULTIPLE_CHECKIN: 'PAGE_MULTIPLE_CHECKIN',
  PAGE_REMIND: 'PAGE_REMIND',
  PAGE_SUCCESS_SINGLE_CHECK_IN: 'PAGE_SUCCESS_SINGLE_CHECK_IN',
  PAGE_SUCCESS_MULTIPLE_CHECK_IN: 'PAGE_SUCCESS_MULTIPLE_CHECK_IN',
  PAGE_MODE_ADMIN: 'PAGE_MODE_ADMIN'
}

const TYPE_TICKET = {
  TYPE_BOOKING: 'TYPE_BOOKING',
  TYPE_WALK_IN: 'TYPE_WALK_IN',
  TYPE_UNIT: 'TYPE_UNIT',
  TYPE_PRINT: 'TYPE_PRINT',
  REMOVE: 'REMOVE'
}

const TYPE_PAGE_TICKET = {
  PAGE_ANTRIAN_SUCCESS: 'PAGE_ANTRIAN_SUCCESS',
  PAGE_ANTRIAN_LIST_DOKTER: 'PAGE_ANTRIAN_LIST_DOKTER',
  PAGE_MODE_ANTRIAN: 'PAGE_MODE_ANTRIAN',
  PAGE_ANTRIAN_ERROR: 'PAGE_ANTRIAN_ERROR',
  PAGE_ANTRIAN_LIST_SUCCESS: 'PAGE_ANTRIAN_LIST_SUCCESS',
  PAGE_DOUBLE_PASIEN: 'PAGE_DOUBLE_PASIEN',
  PAGE_SERVER_ERROR: 'PAGE_SERVER_ERROR',
  PAGE_PRINTER_ERROR: 'PAGE_PRINTER_ERROR'
}

const TYPE_UNIT = {
  DOKTER: 'DOKTER',
  RADIOLOGY: 'RADIOLOGY',
  MEDICAL_REHAB: 'MEDICAL_REHAB',
  NUKLIR: 'NUKLIR'
}

const ERROR_PRINT = {
  EMPTY_PAPER: 'EMPTY_PAPER',
  OTHER_ERROR_PRINT: 'OTHER_ERROR_PRINT'
}

const ENUM_SETUP = {
  ...MAIN_KIOSK,
  ...KIOSK_CHECKIN,
  ...PAGE_KIOSK_CHECKIN,
  ...TYPE_TICKET,
  ...TYPE_PAGE_TICKET,
  ...TYPE_UNIT,
  ...ERROR_PRINT
}
export default ENUM_SETUP
