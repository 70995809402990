export default {
  namespaced: true,
  state: {
    isQrListen: false,
    textQR: ''
  },
  mutations: {
    SET_IS_LISTEN_QR (state, res) {
      state.isQrListen = res
    },
    SET_TEXT_QR (state, res) {
      state.textQR = res
    }
  },
  actions: {
    LISTEN_QR ({ state }, active) {
      let isTimeOutEnter; let tempText = ''
      const onCheckKeyUp = (e) => {
        const textInput = e.key || String.fromCharCode(e.keyCode)
        const targetName = e.target.localName
        if (e.keyCode === 13) {
          alert('masukk disini')
          clearTimeout(isTimeOutEnter)
          isTimeOutEnter = setTimeout(() => {
            state.textQR = tempText
            alert('masuk -->' + state.textQR)
            tempText = ''
            // alert(resultQR) -- > hasil pesan untuk
          }, 100)
        }
        if (textInput && textInput.length === 1 && targetName !== 'input') {
          tempText += textInput
        }
      }
      if (active) {
        document.addEventListener('keyup', onCheckKeyUp)
      } else {
        document.removeEventListener('keyup', onCheckKeyUp)
      }
    }
  }
}
