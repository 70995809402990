import axiosServer from '@/services/ApiInServer'
const stylePrint = (value) => {
  return JSON.stringify({
    type: 'style',
    value
  })
}

const alignPrint = (value) => {
  return JSON.stringify({
    type: 'align',
    value
  })
}

const fontPrint = (value) => {
  return JSON.stringify({
    type: 'font',
    value
  })
}

const sizePrint = (value1, value2) => {
  return JSON.stringify({
    type: 'size',
    value: [value1, value2]
  })
}

const textPrint = (value) => {
  return JSON.stringify({
    type: 'text',
    value
  })
}
const feedPrint = (value) => {
  return JSON.stringify({
    type: 'feed',
    value
  })
}

const qrPrint = (qr, margin, size) => {
  return JSON.stringify({
    type: 'qr',
    value: {
      code: qr,
      obj: {
        type: 'png',
        margin,
        size
      }
    }
  })
}

const cutPrint = (value, value2) => {
  return JSON.stringify({
    type: 'cut',
    value: [value, value2]
  })
}

const close = () => {
  return JSON.stringify({
    type: 'close'
  })
}

const printServer = ({ header, checkInTime, antrian, cluster, dokter, qrCode, time, reprinted }) => {
  const dokterPrint = []
  if (dokter && dokter.length > 0) {
    dokterPrint.push(`${sizePrint(0, 0.4)}`)
    for (let i = 0; i < dokter.length; i++) {
      dokterPrint.push(`${textPrint(dokter[i].name)}`)
      dokterPrint.push(`${textPrint(dokter[i].poli)}`)
      dokterPrint.push(`${textPrint(dokter[i].timeWithDate)}`)
      dokterPrint.push(`${textPrint('----')}`)
    }
  }
  const arrPrint = [
    `${alignPrint('ct')}`,
    `${fontPrint('a')}`,
    ...(header ? [`${sizePrint(1, 1.2)}`, `${textPrint(header)}`] : []),
    ...(checkInTime ? [`${sizePrint(0, 0.4)}`, `${textPrint(checkInTime)}`, `${feedPrint('1')}`] : []),
    `${sizePrint(0, 0.4)}`,
    `${textPrint('Selamat datang')}`,
    `${textPrint('Nomor Tiket Anda:')}`,
    `${feedPrint('2')}`,
    ...(antrian ? [`${sizePrint(4, 4)}`, `${textPrint(antrian)}`, `${feedPrint('2')}`] : []),
    ...(dokterPrint.length > 0 ? dokterPrint : []),
    `${sizePrint(0, 0.4)}`,
    `${textPrint('QR Code:')}`,
    ...(qrCode ? [`${qrPrint(qrCode, 6, 6)}`] : []),
    `${textPrint('Tiket ini digunakan selama kunjungan Anda di')}`,
    `${textPrint('Rumah Sakit, mohon dijaga dengan baik')}`,
    `${feedPrint('1')}`,
    `${textPrint('Silakan scan QR Code di atas untuk menerima')}`,
    `${textPrint('tiket antrian versi digital')}`,
    `${feedPrint('1')}`,
    `${textPrint('Antrian dapat di skip jika nomor yang dipanggil')}`,
    `${textPrint('tidak ada ditempat')}`,
    `${feedPrint('1')}`,
    ...(cluster ? [`${stylePrint('normal')}`, `${textPrint(cluster)}`, `${feedPrint('1')}`] : []),
    ...(reprinted ? [`${textPrint('Re-Printed at')}`] : []),
    `${textPrint('---' + time + '----')}`,
    `${feedPrint('3')}`,
    `${cutPrint(1, 7)}`,
    `${close()}`
  ]
  const arrPrintParse = arrPrint.map(_item => JSON.parse(_item))
  return arrPrintParse
}

export default {
  checkLocalStorage (type) {
    return new Promise((resolve) => {
      if (localStorage.getItem(type)) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  },
  restartKiosk () {
    axiosServer.get('restart')
  },
  shutdownKiosk () {
    axiosServer.get('turn-off')
  },
  async printKiosk (data) {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    const body = {
      data: await printServer(data)
    }
    return axiosServer.post('print-usb', body, headers)
  },
  getStatusPrinter () {
    return axiosServer.get('status-usb')
  },
  errorSlack (context) {
    const nama = context.config.cluster.nama
    const floor = context.config.cluster.floor
    const localDevice = JSON.parse(localStorage['config-kiosk'])
    const body = {
      pretext: `Kiosk Antrian ${nama} | ${floor}`,
      color: '#f90000',
      fields: [
        {
          title: `Gagal Print - ${window.location.origin}`,
          value: `Tiket tidak keluar pada device ${localDevice.deviceId}`,
          short: false
        }
      ]
    }
    return fetch('https://hooks.slack.com/services/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', {
      method: 'POST',
      body: JSON.stringify(body)
    })
  }
}
