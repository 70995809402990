import { createStore } from 'vuex'
import { Repository } from '@/repositories/repository'
import DataFake from '@/repositories/dummy-data'
import QrScan from './QrScan'
import DocumentHandler from './DocumentHandler'
import Language from './Language'
import KioskCheckIn from './KioskCheckIn'
import router from '../router'
import MoreFunction from '@/helpers/moreFunction'
import emitter from '@/services/mitt'

const dataTimeOut = Repository.get('dataTimeOut')
const fromServer = Repository.get('fromServer')
const kioskRepository = Repository.get('kiosk')
const antrianRepository = Repository.get('antrian')

// import router from '../router/index'
export default createStore({
  state: {
    dataTimeOut: DataFake.dataServiceTimeOutReplace(),
    isSCanQR: false,
    // IP: '',
    config: {},
    isLoadingAntrian: false,
    mode_show: '',
    listVideoInRepo: '',
    listVideo: []
  },
  mutations: {
    SET_LIST_VIDEO (state, res) {
      state.listVideo = res
    },
    SET_LIST_VIDEO_IN_REPO (state, res) {
      state.listVideoInRepo = res
    },
    SET_MODE_SHOW (state, res) {
      state.mode_show = res
    },
    SET_IDLE_TIME (state, res) {
      state.idleTime = res
    },
    SET_DATA_TIME_OUT (state, res) {
      state.dataTimeOut = res
    },
    SET_SCAN_QR (state, res) {
      state.isSCanQR = res
    },
    // SET_IP (state, res) {
    //   state.IP = res
    // },
    SET_CONFIG_KIOSK (state, res) {
      state.config = res
    },
    SET_LOADING_ANTRIAN (state, res) {
      state.isLoadingAntrian = res
    }
  },
  actions: {
    async CHANGE_DATA_TIME_OUT ({ commit }) {
      await dataTimeOut.get()
        .then(res => {
          commit('SET_DATA_TIME_OUT', res)
        })
    },
    async GET_CONFIG_POLI ({ commit }) {
      await kioskRepository.getConfigPoli()
        .then(_data => {
          commit('SET_CONFIG_KIOSK', _data)
        })
        .catch(() => {
          console.log('err')
        })
    },
    SET_CONFIG_POLI ({ dispatch }, config) {
      kioskRepository.setConfigPoli(config)
        .then(() => {
          dispatch('GET_CONFIG_POLI')
          router.push({ name: 'Home' })
        })
        .catch(err => console.log(err.message))
    },
    GET_AFTER_CREATE_ANTRIAN (context) {
      return new Promise((resolve, reject) => {
        kioskRepository.getListAntrian()
          .then(_data => {
            if (_data.status >= 200 && _data.status < 400) {
              resolve(_data)
            } else {
              reject(new Error(_data))
            }
          })
          .catch(() => reject(new Error('gagal')))
      })
    },
    GET_LIST_DOKTER_BOOKING (context) {
      return new Promise((resolve, reject) => {
        kioskRepository.getBookingListDokter()
          .then(_data => {
            if (_data.status >= 200 && _data.status < 400) {
              resolve(_data)
            } else {
              reject(new Error(_data))
            }
          })
          .catch(() => reject(new Error('gagal')))
      })
    },
    GET_PING_TIME (context) {
      return new Promise((resolve, reject) => {
        fromServer.getServerPingTime()
          .then(_data => {
            resolve(_data)
          })
          .catch(() => reject(new Error('gagal')))
      })
    },
    PRINTING_KIOSK ({ state }, data) {
      data.header_poli = `${state.config.floor} ${state.config.namePoli}`
      fromServer.printKiosk(data)
        .then(resp => resp.json())
        .then(_data => {
          if (_data.status === 400) {
            emitter.emit('error-page')
          }
        }).catch(err => {
          console.log('err', err.response)
        })
    },
    GET_VIDEO_IN_REPO ({ state, commit }, link) {
      return new Promise((resolve) => {
        kioskRepository.getListVideoInAPI(link)
          .then(_data => {
            console.log(_data)
            commit('SET_LIST_VIDEO_IN_REPO', _data)
            resolve(true)
          })
          .catch(() => new Error('error get Video'))
      })
    },
    async GET_VIDEO_IN_INDEXDB_AND_REPO ({ state, commit, dispatch }, link) {
      let indexDB; let getListInIndexDB = []
      try {
        await dispatch('GET_VIDEO_IN_REPO', link)
        indexDB = await kioskRepository.getDatabase('Video', 'link')
        getListInIndexDB = await kioskRepository.readDataAssetDatabase(indexDB, 'listVideo') || { item: [] }
      } catch {

      }
      // bila ada perbedaan antara video di list repo dan index DB
      const moreFunction = new MoreFunction()
      const diffItem = moreFunction.checkDiffId(getListInIndexDB.item, state.listVideoInRepo)
      if (diffItem.length > 0) {
        diffItem.forEach(element => {
          kioskRepository.deleteDataInDatabase(indexDB, element.id)
        })
      }

      // ambil video di dalam indexDB
      const listFileShow = state.listVideoInRepo.map((item, index) => {
        return {
          id: item.id,
          src: item.src,
          isReady: false,
          type: 'video',
          positon: index
        }
      })
      // add indexDB kedalam key listVideo
      const listVideo = {
        id: 'listVideo',
        item: listFileShow
      }
      kioskRepository.getAfterSaveDatabase(indexDB, 'linkVideo', listVideo)

      // baca listFileShow kedalam indexDB
      for (let i = 0; i < listFileShow.length; i++) {
        try {
          const result = await kioskRepository.readDataAssetDatabase(indexDB, listFileShow[i].id)
          if (result) {
            listFileShow[i].isReady = true
            listFileShow[i].src = window.URL.createObjectURL(result.src)
            commit('SET_LIST_VIDEO', listFileShow)
          } else {
            // download Blob
            kioskRepository.downloadFitur(listFileShow[i].src)
              .then(data => {
                const objectData = {
                  id: listFileShow[i].id,
                  src: data
                }
                listFileShow[i].isReady = true
                listFileShow[i].src = window.URL.createObjectURL(data)
                commit('SET_LIST_VIDEO', listFileShow)
                return objectData
              })
              .then(objectData => {
                // save to indeXDB
                kioskRepository.SaveDataToDatabase(indexDB, objectData)
              })
              .catch(err => {
                console.log('error database', err.message)
              })
          }
        } catch (err) {
          console.log('error', 'iyeeee')
        }
        setTimeout(() => {
          return true
        }, 10000)
      }
    },
    async GET_ANTRIAN_INFO (context, code) {
      return new Promise((resolve, reject) => {
        antrianRepository.getStatusAntrianKasir(code)
          .then(_data => {
            if (_data.status >= 200 && _data.status < 400) {
              resolve(_data)
            } else {
              reject(new Error(_data))
            }
          })
          .catch(() => reject(new Error('gagal')))
      })
    }
  },
  modules: {
    QrScan,
    DocumentHandler,
    Language,
    KioskCheckIn
  }
})
