<template>
  <!-- <button style="z-index:12; position: absolute; opacity: 0" @click="showHide">Show</button> -->
  <!-- <div class="position-stately-test p-2" :class="isShow ? 'transition-show' : ''">
     <div class="d-flex flex-column" v-if="!pageCheckIn && !pageAntrian">
        <div class="d-flex align-contentr-start flex-wrap mb-2">
          <button class="btn-promise " @click="notoken" v-if="kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.init:invocation[0]')">no token</button>
          <button class="btn-promise " @click="withToken" v-if="kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.init:invocation[0]')">With token</button>
        </div>
        <div class="d-flex align-contentr-start flex-wrap mb-2">
        <button class="btn-promise "  @click="socketConnect" v-if="kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.SOCKET_CONNECT_WITH_TOKEN:invocation[0]') || kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.SOCKET_CONNECT:invocation[0]')">Socket Connect</button>
        <button class="btn-promise "  @click="socketDisconnect" v-if="kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.SOCKET_CONNECT_WITH_TOKEN:invocation[0]') || kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.SOCKET_CONNECT:invocation[0]')">Socket Reconnect</button>
       </div>
       <div class="d-flex align-content-start flex-wrap mb-2">
        <button class="btn-promise "  @click="closeConnectionDone" v-if="kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.AFTER_PERMIT_SOCKET.CLOSE_CONNECTION:invocation[0]')">Close Connection done</button>
        <button class="btn-promise "  @click="closeConnectionError" v-if="kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.AFTER_PERMIT_SOCKET.CLOSE_CONNECTION:invocation[0]')">Close Connection Already</button>
        <button class="btn-promise "  @click="closeConnectionErrorRetry" v-if="kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.AFTER_PERMIT_SOCKET.CLOSE_CONNECTION:invocation[0]')">Close Connection Retry</button>
       </div>
        <div class="d-flex align-content-start flex-wrap mb-2">
        <button class="btn-promise "  @click="saveLocalStorageDone" v-if="kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.AFTER_PERMIT_SOCKET.SAVE_TOKEN_LOCALSTORAGE:invocation[0]')">Save localStorage Done</button>
        <button class="btn-promise "  @click="saveLocalStorageError" v-if="kioskSetup('done.invoke.MainKiosk.CONNECTING_AUTHORIZING.AFTER_PERMIT_SOCKET.SAVE_TOKEN_LOCALSTORAGE:invocation[0]')">Save localStorage Error</button>
       </div>
       <div class="d-flex align-content-start flex-wrap mb-2">
        <button class="btn-promise "  @click="configValid" v-if="kioskSetup('done.invoke.MainKiosk.CONFIGURING.init:invocation[0]')">get config valid - cluster 1</button>
        <button class="btn-promise "  @click="configValid2" v-if="kioskSetup('done.invoke.MainKiosk.CONFIGURING.init:invocation[0]')">get config valid - cluster 2</button>
        <button class="btn-promise "  @click="configValid3" v-if="kioskSetup('done.invoke.MainKiosk.CONFIGURING.init:invocation[0]')">get config valid - cluster 3</button>
         <button class="btn-promise "  @click="configValidAntrian" v-if="kioskSetup('done.invoke.MainKiosk.CONFIGURING.init:invocation[0]')">get config valid - antrian </button>
        <button class="btn-promise "  @click="configInvalid" v-if="kioskSetup('done.invoke.MainKiosk.CONFIGURING.init:invocation[0]')">get config invalid</button>
        <button class="btn-promise "  @click="configError" v-if="kioskSetup('done.invoke.MainKiosk.CONFIGURING.init:invocation[0]')">get config error</button>
       </div>
      <div class="d-flex align-content-start flex-wrap mb-2">
        <button  class="btn-event " @click="onlineAction">Online</button>
        <button class="btn-event " @click="onlineActionWithSocket">Online Socket</button>
        <button class="btn-event " @click="offlineAction">Offline</button>
      </div>
       <div class="d-flex align-content-start flex-wrap mb-2">
        <button class="btn-event "  @click="rejectDevice" v-if="kioskSetup('on:admin:reject')">Reject Device</button>
       </div>
       <div class="d-flex align-content-start flex-wrap mb-2">
        <button class="btn-event " @click="sendToken" v-if="kioskSetup('on:config:permit')">Send Token</button>
       </div>
       <div class="d-flex align-content-start flex-wrap mb-2">
         <button class="btn-event " @click="processValid" v-if="kioskSetup('done.invoke.MainKiosk.CONFIGURING.READY_IDLE:invocation[0]')">To Page kiosk</button>
       </div>
      <div class="d-flex align-content-start flex-wrap mb-2">
        <button class="btn-event " @click="configPush" v-if="kioskSetup('on:config:push')">Config Push - Cluster</button>
        <button class="btn-event " @click="configPushType2" v-if="kioskSetup('on:config:push')">Config Push - Cluster 2</button>
        <button class="btn-event " @click="configPushType3" v-if="kioskSetup('on:config:push')">Config Push - Cluster 3</button>
        <button class="btn-event " @click="configPush2" v-if="kioskSetup('on:config:push')">Config Push - Pendaftaran - 1</button>
        <button class="btn-event " @click="configPush21" v-if="kioskSetup('on:config:push')">Config Push - Pendaftaran - 2</button>
        <button class="btn-event " @click="configPush22" v-if="kioskSetup('on:config:push')">Config Push - Pendaftaran - 3</button>
        <button class="btn-event " @click="configPushError" v-if="kioskSetup('on:config:push')">Config Push Error</button>
       </div>
     </div>
     <div class="d-flex flex-column" v-if="pageCheckIn">
        <div class="d-flex align-contentr-start flex-wrap mb-2">
          <button class="btn-event " @click="loadConfig">Load Page</button>
        </div>
         <div class="d-flex align-contentr-start flex-wrap mb-2">
            <button class="btn-event " @click="scanTicketNormal" v-if="kioskShow('scan:ticket:kiosk')">Scan Ticket</button>
            <button class="btn-event " @click="scanTicketAdmin" v-if="kioskShow('scan:ticket:kiosk')">Scan Ticket Admin</button>
            <button class="btn-event " @click="scanTicketError" v-if="kioskShow('scan:ticket:kiosk')">Scan Ticket Other</button>
        </div>
        <div class="d-flex align-contentr-start flex-wrap mb-2">
          <button class="btn-event" id="ticket-1" @click="singleCheckInPoli" v-if="kioskShow('on:checked:ticket')">Check 1 poli - 0020</button>
          <button class="btn-event" id="ticket-11" @click="singleCheckInPoli1" v-if="kioskShow('on:checked:ticket')">Check 1 poli - 0021</button>
          <button class="btn-event" id="ticket-2" @click="multipleCheckInPoli" v-if="kioskShow('on:checked:ticket')">Multiple poli - 2</button>
          <button class="btn-event" id="ticket-2" @click="multipleCheckInPoli2" v-if="kioskShow('on:checked:ticket')">Multiple poli - 3</button>
          <button class="btn-event" id="ticket-3" @click="remindSingleCheckInPoli" v-if="kioskShow('on:checked:ticket')">Remind poli + check 1Poli</button>
          <button class="btn-event" id="ticket-4" @click="remindMultipleCheckInPoli" v-if="kioskShow('on:checked:ticket')"> Remind poli + Multiple poli</button>
          <button class="btn-event" id="ticket-5" @click="singleCheckInPoliDone" v-if="kioskShow('on:checked:ticket')">Single poli Checkin Again</button>
          <button class="btn-event" id="ticket-6" @click="multipleCheckInPoliDone" v-if="kioskShow('on:checked:ticket')">Multiple poli Checkin Again</button>
          <button class="btn-event " id="ticket-7" @click="wrongTicket" v-if="kioskShow('on:checked:ticket')">Wrong Ticket</button>
        </div>
         <div class="d-flex align-contentr-start flex-wrap mb-2">
          <button class="btn-promise" id="ticket-8" @click="errorGetTicket" v-if="kioskShow('on:checkin:ticket')">Error Get Data</button>
          <button class="btn-event" id="ticket-9" @click="successCheckIn" v-if="kioskShow('on:checkin:ticket')">Success Check In</button>
         </div>
     </div>
    <div class="d-flex flex-column" v-if="pageAntrian">
        <div class="d-flex align-contentr-start flex-wrap mb-2">
          <button class="btn-event " @click="loadConfig2">Load Page</button>
          <button class="btn-event " @click="clickEverywhere" v-if="kiosAntriankShow('click:everywhere')">Click dimana saja</button>
           <button class="btn-event " @click="scanBooking" v-if="kiosAntriankShow('scan:ticket')">Scan Booking</button>
            <button class="btn-event " @click="scanTicket" v-if="kiosAntriankShow('scan:ticket')">Scan Antrian</button>
        </div>
        <div class="d-flex align-contentr-start flex-wrap mb-2">
          <button class="btn-event " @click="infoTicket1" v-if="kiosAntriankShow('on:info:booking')">Ticket Booking 1</button>
          <button class="btn-event " @click="infoTicket2" v-if="kiosAntriankShow('on:info:booking')">Ticket Booking 2</button>
        </div>
        <div class="d-flex align-contentr-start flex-wrap mb-2">
          <button class="btn-event " @click="infoNomor1" v-if="kiosAntriankShow('on:info:booking')">Nomor Telepon 1</button>
          <button class="btn-event " @click="infoNomor2" v-if="kiosAntriankShow('on:info:booking')">Nomor Telepon 2</button>
        </div>
        <div class="d-flex align-contentr-start flex-wrap mb-2">
          <button class="btn-event " @click="antrian1" v-if="kiosAntriankShow('on:info:ticket')">Type Antrian 1</button>
          <button class="btn-event " @click="antrian2" v-if="kiosAntriankShow('on:info:ticket')">Type Antrian 2</button>
          <button class="btn-event " @click="antrian3" v-if="kiosAntriankShow('on:info:ticket')">Type Antrian 3</button>
          <button class="btn-event " @click="antrian4" v-if="kiosAntriankShow('on:info:ticket')">Type Antrian 4</button>
         <button class="btn-event " @click="antrian5" v-if="kiosAntriankShow('on:info:ticket')">Antrian 5 - double check in</button>
         <button class="btn-event " @click="antrian6" v-if="kiosAntriankShow('on:info:ticket')">Antrian 6 - belum regist</button>
        </div>
        <div class="d-flex align-contentr-start flex-wrap mb-2">
          <button class="btn-event " @click="createPendaftaran" v-if="kiosAntriankShow('on:create:pendaftaran')">create Pendaftaran</button>
          <button class="btn-event " @click="createPendaftaran2" v-if="kiosAntriankShow('on:create:unit')">Create To Unit</button>
          <button class="btn-event " @click="errorResponse">Error Response</button>
        </div>
     </div>
      <div class="d-flex flex-column">
        <button  @click="pageCheckIn = !pageCheckIn">Kiosk Check in</button>
        <button  @click="pageAntrian = !pageAntrian">Kiosk Antrian</button>
      </div>
  </div> -->
  <div class="position-testing ">
    <div class="d-flex align-content-start flex-wrap">
       <!-- <div class="d-flex flex-column">
        <button class="btn-promise " @click="notoken">no token</button>
        <button class="btn-promise " @click="withToken">With token</button>
       </div> -->
       <!-- <div class="d-flex flex-column">
        <button class="btn-promise "  @click="socketConnect">Socket Connect</button>
        <button class="btn-promise "  @click="socketDisconnect">Socket Reconnect</button>
       </div> -->
    </div>
    <!-- <button @click="offlineACtion">Offline </button>
    <button @click="onlineAction">Online</button> -->
    <label class="text-warning">{{context.socketId}}</label>
    <!-- <button id="emitconfig" @click="setConfigs">Done Setup</button>
    <button @click="removeConfig">Remove Setup</button> -->
  </div>
  <router-view></router-view>
  <!-- <setup-page :data-machine="context" v-if="!idle"/>
  <idle-page-kiosk-checkin v-else/> -->
  <label class="flexible-version">
    v.{{version}} -- {{fetchData}}
  </label>
</template>
<script>
// import io from 'socket-client'
// import SetupPage from '@/views/page/Setup.vue'
// import idlePageKioskCheckin from '@/componentXstate/KioskCheckIn.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import DATA from '@/services/static-data'
import MoreFunction from '@/helpers/moreFunction'
import { assign } from 'xstate'
import { useMachine } from '@xstate/vue'
import { KioskMachine } from '@/machine/kioskCheckin2'
import configRepository from '@/repositories/configRepository'
import socketRepository from '@/repositories/socketRepository'
import kioskAntrianRepository from '@/repositories/KioskAntrian'
import ENUM_SETUP from './enum/typeSetup'
import CheckURL from '@/helpers/checkURL'

export default {
  components: {
    // SetupPage,
    // idlePageKioskCheckin
  },
  data () {
    const that = this
    window.dataMachine = KioskMachine.withConfig({
      services: {
        getFromLocalStorageTokenAndDeviceId: (_data, _config) => {
          return configRepository.configDefaultDeviceIdAndToken()
        },
        // openConnection: (_data, _config) => {
        //   return socketRepository.openSocketConnection()
        // },
        openSocketConnection: (_context, _event) => {
          return socketRepository.openSocketConnection()
        },
        getAPIAwaitDeviceConfig: (_data, _event) => {
          // console.log('errorrr')
          return new Promise((resolve, reject) => {
            configRepository.getConfigFromAPI(_data.token, _data.socketId).then()
              .catch(_err => {
                reject(new Error('err'))
              })
            window.socket.once('RESPONSE:API-DEVICE-CONFIG', (_evt) => {
              try {
                _evt.config = JSON.parse(_evt.config)
              } catch {
                // error
                console.log('error device config')
              }
              if (_evt.type === 'KIOSK_ANTRIAN') {
                _evt = configRepository.howCheckKiosk(_evt)
              }
              resolve(_evt)
            })
          })
        },
        saveConfigToLocalStorage: (_data, _event) => {
          return configRepository.saveConfigToLocalStorage({ data: _data, event: _event })
          // .then((evt) => {
          //   if (evt) {
          //     that.$router.push({ name: 'kioskCheckIn' })
          //   }
          // })
        },
        closeSocketOpenNewConnection: (_context, _event) => {
          // console.log('close Conntextion')
          return socketRepository.closeConnectionSocket(_context.token, _context.deviceId)
        },
        saveTokenToLocalstorage: (_context, _event) => {
          return configRepository.saveTokenOnLocalStorage(_context.token)
        }
      },
      actions: {
        refreshPage: () => {
          location.reload()
        },
        enableCtxManualConnecting: assign({
          manualConnecting: (_context, _event) => {
            return true
          }
        }),
        disableCtxManualConnecting: assign({
          manualConnecting: (_context, _event) => {
            return false
          }
        }),
        deleteCtxStrToken: assign({
          token: (_context, _event) => {
            return ''
          }
        }),
        toPageAdmin () {
          return that.$router.push({ name: 'setting' }).catch(err => console.log(err))
        },
        toPageKioskCluster (context, event) {
          return that.$router.push({ name: 'kioskCheckIn' })
        },
        toPageKioskAntrian (context, event) {
          that.$router.push({ name: 'kioskAntrian' })
        },
        toPageSetup (context, event) {
          return that.$router.push({ name: 'Setup' })
        },
        setCtxObjConfigKiosk: assign({
          config: (_context, _event) => {
            const data = _event.data.config
            if (data.cluster) {
              data.cluster.list = _event.data.cluster
            }
            if (_event.data.serverConfig) {
              data.serverConfig = _event.data.serverConfig
            }
            return data
          }
        }),
        deleteCtxObjConfigKiosk: assign({
          config: (_context, _event) => {
            return {}
          }
        }),
        deleteLocalStorageOnlyToken: () => {
          const localData = JSON.parse(localStorage.getItem('config-kiosk'))
          delete localData.token
          localStorage.setItem('config-kiosk', JSON.stringify(localData))
        },
        saveLocalStorageDeviceAndToken: (context, _event) => {
          localStorage.setItem('config-kiosk', JSON.stringify(_event.data))
        },
        // disconnectSocket (context, event) {
        //   // context.socket.disconnect()
        // },
        deleteLocalStorageKiosk (context, event) {
          localStorage.removeItem('config-kiosk')
        },
        // moveKeIdleScreen (context, event) {
        //   console.log('event -->', event)
        //   if (event.valid) {
        //     that.$router.push({ name: 'kioskCheckIn' })
        //   }
        // },
        // backToSetupPage (context, _event) {
        //   return that.$router.push({ name: 'Setup' })
        // },
        // saveTokenLocalStorage (state, evt) {
        //   console.log('_evt', evt)
        //   localStorage.setItem('haveToken', evt.token)
        // },
        // saveCtxStrToken: assign({
        //   token: (context, _event) => {
        //     return _event.data.token
        //   }
        // }),
        // deleteToken (context, _event) {
        //   return assign({
        //     token: ''
        //   })
        // },
        setCtxStrToken: assign({
          token: (context, _event) => {
            return _event.data.token ? _event.data.token : ''
          }
        }),
        // setConfig: assign({
        //   config: (context, _event) => {
        //     return _event.data
        //   }
        // }),
        setCtxStrDeviceId: assign({
          deviceId: (context, _event) => {
            console.log(_event)
            return _event.data.deviceId
          }
        }),
        // flagOnline: assign({
        //   connected: (context, _event) => {
        //     console.log('console flag', _event)
        //     return _event.connected
        //   }
        // }),
        // flagOffline: assign({
        //   connected: (context, _event) => {
        //     return _event.connected
        //   }
        // }),
        setCtxBoolConnected: assign({
          connected: (context, _event) => {
            this.setOffline(false)
            return true
          }
        }),
        setCtxBoolDisconnected: assign({
          connected: (context, _event) => {
            this.setOffline(true)
            return false
          }
        }),
        listenSocketGranted: () => {
          window.socket.once('DEVICE:GRANTED', (_evt) => {
            window.fetchMachine.send({
              type: 'on:config:permit',
              data: { ..._evt }
            })
          })
        },
        // setLoadingFalse: (context, event) => {
        //   console.log('setLoadingFalse', context)
        // },
        // setLoadingTrue: (context, event) => {
        //   console.log('setLoadingTrue', context)
        // },
        // moveKeGearScreen: (context, event) => {
        //   this.idle = false
        // },
        setCtxStrSocketId: assign({
          socketId: (context, _event) => {
            return window.socket.id
          }
          // socket: (context, _event) => {
          //   return _event.data.socket
          // }
        })
        // closeConnection: assign({
        //   socket: false
        // })
        // closeConnection: assign({
        //   socketId: (context, _event) => {
        //     return ''
        //   }
        // })
      },
      guards: {
        // invalidToken: (_context, _event) => {
        //   return _event.error === 'token invalid'
        // },
        isSameToken: (context, _evt) => {
          const token = context.token === _evt.data.token
          return token
        },
        isAlreadyClose (context, event) {
          return event.data.isAlreadyClose
        },
        isKioskCluster (context, event) {
          return context.config.screen === 'kioskCheckIn'
        },
        isKioskAntrian (context, event) {
          return context.config.screen === 'kioskAntrian'
        },
        hasCtxToken: (_context, event) => {
          const hasToken = _context.token
          return hasToken
        },
        // tokenInvalid: (_context, _event) => {
        //   console.log('guards false')
        //   console.log('guard event', _event.data)
        //   return false
        // },
        // connected: (_context, _event) => {
        //   return _event.cnt
        // },
        // hasToken: (_context, _event) => {
        //   return true
        // },
        hasConfigObject: (_context, _event) => {
          return _event.data.type
          // return _event.config
        },
        isValidKiosk: (_context, _event) => {
          // console.log('_evt-----', _event)
          const config = _event.data ? _event.data.config : _event.config
          const kiosk = config.screen === 'kioskCheckIn' || config.screen === 'kioskAntrian'
          return kiosk
          // return true
        },
        isManualConnecting: (context, _event) => {
          return context.manualConnecting
        }
      }
    })
    const modeDevTools = process.env.VUE_APP_DEVELOPMENT ? { devTools: true } : { devTools: false }
    const { state, service } = useMachine(window.dataMachine, modeDevTools)
    window.fetchMachine = service
    window.stateMachine = state
    // this.fetchMachine = useMachine(dataMachine, { devTools: true })
    return {
      version: process.env.VUE_APP_COMMIT_ID,
      fetchData: process.env.VUE_APP_API_URL,
      machineKiosk: '',
      fetchMachine: {},
      isOfflineMachine: true,
      idle: false,
      context: state,
      isShowToken: false,
      isShow: false,
      pageCheckIn: false,
      pageAntrian: false,
      tempText: '',
      resultText: ''
    }
  },
  computed: {
    ...mapState({
      IPAddress: (state) => state.IP
    }),
    ...mapState('DocumentHandler', {
      isErrorPage: (state) => state.isErrorPage,
      isOffline: (state) => state.isOffline
    })
  },
  async mounted () {
    document.removeEventListener('keypress', this.checkKeyPress)
    document.addEventListener('keypress', this.checkKeyPress)
    window.loadingType = 1
    const socketIo = window.socket.connect()
    socketIo.on('connect', () => {
      window.fetchMachine.send({
        type: 'on:connected'
      })
    })

    socketIo.on('DEVICE:REFRESH', (_evt) => {
      window.fetchMachine.send({
        type: 'reload:page'
      })
    })

    socketIo.on('DEVICE:ATTACH', (_evt) => {
      window.fetchMachine.send({
        type: 'on:config:permit',
        data: { ..._evt }
      })
    })
    socketIo.on('connected', (_evt) => {
      window.fetchMachine.send({
        type: 'on:connected',
        data: {
          socketId: _evt.id
        }
      })
    })

    socketIo.on('DEVICE:INVALID', (_evt) => {
      window.fetchMachine.send({
        type: 'on:admin:reject'
      })
    })

    socketIo.on('DEVICE:INVALID_TOKEN', (_evt) => {
      window.fetchMachine.send({
        type: 'on:admin:delete:token',
        data: {
          token: _evt.token
        }
      })
    })

    socketIo.on('disconnect', () => {
      window.fetchMachine.send({
        type: 'on:disconnect'
      })
    })

    socketIo.on('DEVICE:SAVE-REFRESH-DEVICE', (_evt) => {
      let data = { ..._evt }
      data.config = JSON.parse(data.config)
      if (data.type === 'KIOSK_ANTRIAN') {
        data = configRepository.howCheckKiosk(data)
      }
      window.fetchMachine.send({
        type: 'on:config:push',
        data: data
      })
      if (window.fetchKioskCheckIn) {
        window.fetchKioskCheckIn.send({
          type: 'push:config',
          data
        })
      } else if (window.machineKiosk) {
        window.machineKiosk.send({
          type: 'push:config',
          data: data.config
        })
      }
    })
  },
  methods: {
    checkKeyPress (e) {
      const textInput = e.key || String.fromCharCode(e.keyCode)
      const targetName = e.target.localName
      if (textInput && textInput.length === 1 && targetName !== 'input') {
        this.tempText += textInput
      }
      // console.log('-->', e.keyCode)
      if (e.keyCode === 13) {
        this.resultText = this.tempText
        this.tempText = ''
        if (this.runKeyUpScanner) clearTimeout(this.runKeyUpScanner)
        this.runKeyUpScanner = setTimeout(() => {
          const configLocalStorage = localStorage.config ?? null
          const isConfigAdmin = JSON.parse(configLocalStorage) ?? {}
          if (!window.fetchKioskCheckIn && ((isConfigAdmin.urlAdmin && isConfigAdmin.urlAdmin === this.resultText) || process.env.VUE_APP_ADMIN_PAGE === this.resultText)) {
            window.fetchMachine.send({
              type: 'go:to:admin'
            })
          } else if (window.machineKiosk) {
            try {
              const url = new CheckURL()
              const ticketType = url.mapperTicket(this.resultText)
              if (ticketType) {
                if (ticketType.type === ENUM_SETUP.TYPE_PRINT) {
                  window.machineKiosk.send({
                    type: 'scan:reprint:ticket',
                    data: ticketType.generateId
                  })
                } else {
                  window.machineKiosk.send({
                    type: 'scan:ticket',
                    data: ticketType
                  })
                }
              } else {
                const moreFunction = new MoreFunction()
                moreFunction.createToast()
              }
            } catch {
              const moreFunction = new MoreFunction()
              moreFunction.createToast()
            }
          } else if (window.fetchKioskCheckIn) {
            const url = new CheckURL()
            const ticket = url.getParamsURLWithTicket(this.resultText)
            if (ticket.generateId) {
              window.fetchKioskCheckIn.send({
                type: 'scan:ticket:kiosk',
                data: ticket
              })
            } else {
              window.fetchKioskCheckIn.send({
                type: 'scan:ticket:kiosk',
                data: ticket
              })
            }
          }
        }, 100)
      }
      if (this.timeRemoveTempText) clearTimeout(this.timeRemoveTempText)
      this.timeRemoveTempText = setTimeout(() => {
        this.tempText = ''
      }, 1000)
    },
    kioskShow (name) {
      if (window.stateKioskCheckIn && window.stateKioskCheckIn.value) {
        const list = window.stateKioskCheckIn.value.nextEvents
        return (list.indexOf(name) > -1)
      }
      return false
    },
    kiosAntriankShow (name) {
      if (window.stateMachineKiosk && window.stateMachineKiosk.value) {
        const list = window.stateMachineKiosk.value.nextEvents
        return (list.indexOf(name) > -1)
      }
      return false
    },
    kioskSetup (name) {
      if (window.stateMachine && window.stateMachine.value) {
        const list = window.stateMachine.value.nextEvents
        return (list.indexOf(name) > -1)
      }
      return false
    },
    ...mapActions({
      getIp: 'GET_IP',
      setConfig: 'SET_CONFIG_POLI',
      getUUID: 'DocumentHandler/SET_CHECK_UUID',
      getConfig: 'GET_CONFIG_POLI'
    }),
    ...mapMutations({
      setErrorPage: 'DocumentHandler/SET_ERROR_PAGE',
      setOffline: 'DocumentHandler/SET_IS_OFFLINE'
    }),
    commandSocketString (item) {
      const dataItem = item
      try {
        dataItem.text = JSON.parse(dataItem.text) || {}
      } catch {
        // console.error('not support')
      }
      this.actionString(dataItem.command, dataItem.text)
    },
    actionString (command, value) {
      if (command === DATA.MODE_COMMAND.OFFLINE) {
        this.setErrorPage(true)
      } else if (command === DATA.MODE_COMMAND.ONLINE) {
        this.setErrorPage(false)
      } else if (command === DATA.MODE_COMMAND.CLEAR_STORAGE) {
        localStorage.clear()
        this.$router.push({ name: 'Setup' })
      } else if (command === DATA.MODE_COMMAND.SET_CONFIG) {
        this.setConfig(value)
      }
    },
    offlineAction () {
      window.fetchMachine.send({
        type: 'on:disconnect'
      })
    },
    onlineAction () {
      window.fetchMachine.send({
        type: 'on:connected'
      })
    },
    onlineActionWithSocket () {
      window.fetchMachine.send({
        type: 'on:connected',
        data: {
          socketId: Math.floor(Math.random() * 10000000000)
        }
      })
    },
    removeConfig () {
      window.fetchMachine.send({
        type: 'on:config:push',
        connected: true
      })
      this.idle = false
    },
    setConfigs () {
      window.fetchMachine.send({
        type: 'on:config:push',
        connected: true
      })
    },
    sendToken () {
      window.fetchMachine.send({
        type: 'on:config:permit',
        data: {
          token: '1111111111222222222'
        }
      })
    },
    rejectDevice () {
      window.fetchMachine.send({
        type: 'on:admin:reject',
        router: 'setup'
      })
    },
    configPush () {
      console.log('masukkk log')
      window.fetchMachine.send({
        type: 'on:config:push',
        data: {
          config: {
            screen: 'kioskCheckIn',
            cluster: {
              id: '123',
              nama: 'Jantung & Pembuluh Darah',
              info: 'Lt. 2 | Poli Jantung',
              rs: 'RS. Mandaya Royal Puri'
            },
            delay: {
              buttonLinearCancelDelay: 2,
              buttonLinearCancelProgress: 10,
              buttonLinearCancelAfter: 2,
              timeOutCancel: 30
            }
          }
        }
      })
    },
    configPushType2 () {
      window.fetchMachine.send({
        type: 'on:config:push',
        data: {
          config: {
            screen: 'kioskCheckIn',
            cluster: {
              id: '124',
              nama: 'Penyakit Dalam',
              info: 'Lt. 1 | Penyakit Dalam',
              rs: 'RS. Mandaya Karawang'
            },
            delay: {
              buttonLinearCancelDelay: 2,
              buttonLinearCancelProgress: 10,
              buttonLinearCancelAfter: 2,
              timeOutCancel: 30
            }
          }
        }
      })
    },
    configPushType3 () {
      window.fetchMachine.send({
        type: 'on:config:push',
        data: {
          config: {
            screen: 'kioskCheckIn',
            cluster: {
              id: '125',
              nama: 'Obygin',
              info: 'Lt. 11 | Obygin',
              rs: 'RS. Mandaya Royal Puri'
            },
            delay: {
              buttonLinearCancelDelay: 2,
              buttonLinearCancelProgress: 10,
              buttonLinearCancelAfter: 2,
              timeOutCancel: 30
            }
          }
        }
      })
    },
    configPush2 () {
      window.fetchMachine.send({
        type: 'on:config:push',
        data: kioskAntrianRepository.get('CONFIG_1')
      })
    },
    configPush21 () {
      window.fetchMachine.send({
        type: 'on:config:push',
        data: kioskAntrianRepository.get('CONFIG_2')
      })
    },
    configPush22 () {
      window.fetchMachine.send({
        type: 'on:config:push',
        data: kioskAntrianRepository.get('CONFIG_3')
      })
    },
    configPushError () {
      window.fetchMachine.send({
        type: 'on:config:push',
        data: {
          config: {
            screen: 'DS POLI',
            cluster: {
              id: '123',
              nama: 'Poli Jantung'
            }
          }
        }
      })
    },
    // showHide () {
    //   this.isShow = !this.isShow
    // },
    notoken () {
      console.log('esaoas', ENUM_SETUP.NO_TOKEN)
      window.config.set({ type: ENUM_SETUP.NO_TOKEN })
    },
    withToken () {
      window.config.set({ type: ENUM_SETUP.WITH_TOKEN })
    },
    socketConnect () {
      window.config.set({ type: ENUM_SETUP.SOCKET_CONNECT })
    },
    socketDisconnect () {
      window.config.set({ type: ENUM_SETUP.SOCKET_DISCONNECT })
    },
    saveLocalStorageError () {
      window.config2.set({ type: ENUM_SETUP.LOCALSTORAGE_ERROR })
    },
    saveLocalStorageDone () {
      window.config2.set({ type: ENUM_SETUP.LOCALSTORAGE_DONE })
    },
    closeConnectionErrorRetry () {
      window.config3.set({ type: ENUM_SETUP.CONNECTION_CLOSE_RETRY })
    },
    closeConnectionError () {
      window.config3.set({ type: ENUM_SETUP.CONNECTION_CLOSE_ERROR })
    },
    closeConnectionDone () {
      window.config3.set({ type: ENUM_SETUP.CONNECTION_CLOSE })
    },
    configValid () {
      window.config.set({ type: ENUM_SETUP.CONFIG_VALID, data: 1 })
    },
    configValid2 () {
      window.config.set({ type: ENUM_SETUP.CONFIG_VALID, data: 2 })
    },
    configValid3 () {
      window.config.set({ type: ENUM_SETUP.CONFIG_VALID, data: 3 })
    },
    configValidAntrian () {
      window.config.set({ type: ENUM_SETUP.CONFIG_VALID_ANTRIAN })
    },
    configError () {
      window.config.set({ type: ENUM_SETUP.CONFIG_ERROR })
    },
    configInvalid () {
      window.config.set({ type: ENUM_SETUP.CONFIG_INVALID })
    },
    processValid () {
      window.config.set({ type: ENUM_SETUP.SAVE_CONFIG_TOLOCAL })
    },
    scanTicketNormal () {
      try {
        // window.fetchKioskCheckIn.
        window.fetchKioskCheckIn.send({
          type: 'scan:ticket:kiosk',
          data: {
            isPasien: true
            // ticket: `https://caredokter.id?ticket=${Math.floor(Math.random() * 10000)}`
          }
        })
      } catch {
        console.log('errorr scan')
      }
    },
    scanTicketAdmin () {
      window.fetchKioskCheckIn.send({
        type: 'scan:ticket:kiosk',
        data: {
          isAdmin: true
          // ticket: `https://caredokter.id?ticket=${Math.floor(Math.random() * 10000)}&admin=1`
        }
      })
    },
    scanTicketError () {
      window.fetchKioskCheckIn.send({
        type: 'scan:ticket:kiosk',
        data: {
          // ticket: `https://caredokter.id?ticket=${Math.floor(Math.random() * 10000)}&admin=1`
        }
      })
    },
    wrongTicket () {
      window.fetchKioskCheckIn.send({
        type: 'on:checked:ticket',
        data: {
          notThisCluster: [
            {
              id: 1,
              hasRemind: false,
              dokter: {
                name: 'DR. dr. Alfred S Sim, Sp. BS (K), FINSS, FINPS',
                spesialis: 'Bedah Umum, Tulang, Pencernaan',
                room: 'R.213',
                time: '13:00',
                floor: 'Lt. 2'
              }
            },
            {
              id: 2,
              hasRemind: false,
              dokter: {
                name: 'dr. Nancy Indah Lestari, SpPD',
                spesialis: 'Penyakit Dalam',
                room: 'R.213',
                time: '10:30',
                floor: 'Lt. 2'
              }
            },
            {
              id: 3,
              hasRemind: false,
              dokter: {
                name: 'dr. Kevin Triangto, BMEdSc.(Hons.), Sp.KFR',
                spesialis: 'Rehabilitasi Medik',
                room: 'R.213',
                time: '15:30',
                floor: 'Lt. 2'
              }
            }
          ]
          // ticket: `https://caredokter.id?ticket=${Math.floor(Math.random() * 10000)}&admin=1`
        }
      })
    },
    singleCheckInPoli () {
      window.fetchKioskCheckIn.send({
        type: 'on:checked:ticket',
        data: {
          thisCluster: [
            {
              id: 1,
              hasCheckIn: false,
              noTicket: '0020',
              date: '13 Desember 2022',
              time: '10:00',
              dokter: {
                name: 'dr. Nancy Indah Lestari, SpPd',
                spesialis: 'Penyakit Dalam',
                room: 'R.15',
                time: '16:00'
              }
            }
          ]
        }
      })
    },
    singleCheckInPoli1 () {
      window.fetchKioskCheckIn.send({
        type: 'on:checked:ticket',
        data: {
          thisCluster: [
            {
              id: 1,
              hasCheckIn: false,
              noTicket: '0021',
              date: '13 Desember 2022',
              time: '15:00',
              dokter: {
                name: 'dr. Abhirama ',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.16',
                time: '17:00'
              }
            }
          ]
        }
      })
    },
    multipleCheckInPoli () {
      window.fetchKioskCheckIn.send({
        type: 'on:checked:ticket',
        data: {
          noTicket: '0021',
          date: '13 Desember 2022',
          time: '15:00',
          thisCluster: [
            {
              id: 1,
              hasCheckIn: false,
              dokter: {
                name: 'dr. Abhirama Nofandra Putra, Sp.JP, FIHA',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.213',
                time: '10:00'
              }
            },
            {
              id: 2,
              hasCheckIn: false,
              dokter: {
                name: 'DR. dr. Alfred S Sim, Sp. BS (K), FINSS, FINPS',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.204',
                time: '11:30'
              }
            }
          ]
        }
      })
    },
    multipleCheckInPoli2 () {
      window.fetchKioskCheckIn.send({
        type: 'on:checked:ticket',
        data: {
          noTicket: '0021',
          date: '13 Desember 2022',
          time: '15:00',
          thisCluster: [
            {
              id: 1,
              hasCheckIn: false,
              dokter: {
                name: 'dr. Abhirama Nofandra Putra, Sp.JP, FIHA',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.213',
                time: '10:00'
              }
            },
            {
              id: 2,
              hasCheckIn: false,
              dokter: {
                name: 'DR. dr. Alfred S Sim, Sp. BS (K), FINSS, FINPS',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.204',
                time: '11:30'
              }
            },
            {
              id: 3,
              hasCheckIn: false,
              dokter: {
                name: 'dr. Nancy AlfredSim, Sp. BS (K), FINSS, FINPS',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.205',
                time: '11:30'
              }
            }
          ]
        }
      })
    },
    remindSingleCheckInPoli () {
      window.fetchKioskCheckIn.send({
        type: 'on:checked:ticket',
        data: {
          noTicket: '0021',
          date: '13 Desember 2022',
          time: '15:00',
          thisCluster: [
            {
              id: 1,
              hasCheckIn: false,
              dokter: {
                name: 'DR.dr Alfred S Sim, Sp. BS (K), FINSS, FINPS',
                spesialis: 'Penyakit Dalam',
                room: 'R.15',
                time: '15:00'
              }
            }
          ],
          notThisCluster: [
            {
              id: 1,
              hasRemind: true,
              dokter: {
                name: 'dr. Nancy Indah Lestari, SpPd',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.213',
                time: '10:00'
              }
            },
            {
              id: 1,
              hasRemind: true,
              dokter: {
                name: 'dr. Nancy Indah Lestari, SpPd',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.213',
                time: '10:00'
              }
            }
          ]
        }
      })
    },
    remindMultipleCheckInPoli () {
      window.fetchKioskCheckIn.send({
        type: 'on:checked:ticket',
        data: {
          noTicket: '0021',
          date: '13 Desember 2022',
          time: '15:00',
          thisCluster: [
            {
              id: 1,
              hasCheckIn: false,
              dokter: {
                name: 'dr. Abhirama Nofandra Putra, Sp.JP, FIHA',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.213',
                time: '10:00'
              }
            },
            {
              id: 2,
              hasCheckIn: false,
              dokter: {
                name: 'DR. dr. Alfred S Sim, Sp. BS (K), FINSS, FINPS',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.204',
                time: '11:30'
              }
            }
          ],
          notThisCluster: [
            {
              id: 1,
              hasRemind: true,
              dokter: {
                name: 'dr. Abhirama Nofandra Putra, Sp.JP, FIHA',
                spesialis: 'Jantung & Pembuluh Darah',
                room: 'R.213',
                time: '10:00'
              }
            }
          ]
        }
      })
    },
    singleCheckInPoliDone () {
      window.fetchKioskCheckIn.send({
        type: 'on:checked:ticket',
        data: {
          list: [
            {
              id: 14,
              numberAntrian: '0019',
              status: 'Waiting'
            },
            {
              id: 15,
              numberAntrian: '0030',
              status: 'Waiting'
            },
            {
              id: 16,
              numberAntrian: '0020',
              status: 'Waiting',
              selected: true
            }
          ],
          thisCluster: [
            {
              id: 1,
              hasCheckIn: true,
              dokter: {
                name: 'dr. Arwin S. Mangkuanom, SpJP (K), FIHA',
                spesialis: 'Jantung & Pembuluh Darah Sub. Intervensi Kardiologi',
                room: 'R.013',
                time: '13:00'
              }
            }
          ]
        }
      })
    },
    multipleCheckInPoliDone () {
      window.fetchKioskCheckIn.send({
        type: 'on:checked:ticket',
        data: {
          thisCluster: [
            {
              id: 1,
              hasCheckIn: true,
              dokter: {
                name: 'dr. Arwin S. Mangkuanom, SpJP (K), FIHA',
                spesialis: 'Jantung & Pembuluh Darah Sub. Intervensi Kardiologi',
                room: 'R.013',
                time: '13:00'
              }
            },
            {
              id: 2,
              hasCheckIn: true,
              dokter: {
                name: 'DR. dr. Alfred S Sim, Sp. BS (K), FINSS, FINPS',
                spesialis: 'Bedah Saraf',
                room: 'R.013',
                time: '13:00'
              }
            }
          ]
        }
      })
    },
    successCheckIn () {
      window.fetchKioskCheckIn.send({
        type: 'on:checkin:ticket',
        data: {
          isCheckIn: true,
          list: [
            {
              id: 14,
              numberAntrian: '0019',
              status: 'Waiting'
            },
            {
              id: 15,
              numberAntrian: '0030',
              status: 'Waiting'
            },
            {
              id: 16,
              numberAntrian: '0020',
              status: 'Waiting',
              selected: true
            }
          ]
        }
      })
    },
    errorGetTicket () {
      window.checkIn.set({ type: ENUM_SETUP.ERROR_GET_TICKET })
    },
    loadConfig () {
      window.fetchKioskCheckIn.send({
        type: 'push:config'
      })
    },
    clickEverywhere () {
      window.machineKiosk.send({
        type: 'click:everywhere'
      })
    },
    scanBooking () {
      window.machineKiosk.send({
        type: 'scan:ticket',
        data: kioskAntrianRepository.get('TICKET_BOOKING')
      })
    },
    scanTicket () {
      window.machineKiosk.send({
        type: 'scan:ticket',
        data: kioskAntrianRepository.get('TICKET_ANTRIAN')
      })
    },
    createPendaftaran () {
      window.machineKiosk.send({
        type: 'on:create:pendaftaran',
        data: kioskAntrianRepository.get('SUCCESS_TABLE')
      })
    },
    createPendaftaran2 () {
      window.machineKiosk.send({
        type: 'on:create:unit',
        data: kioskAntrianRepository.get('SUCCESS_TABLE_2')
      })
    },
    // createPendaftaran2 () {
    //   window.machineKiosk.send({
    //     type: 'on:create:unit',
    //     data: kioskAntrianRepository.get('SUCCESS_TABLE')
    //   })
    // },
    loadConfig2 () {
      window.machineKiosk.send({
        type: 'push:config'
      })
    },
    infoTicket1 () {
      window.machineKiosk.send({
        type: 'on:info:booking',
        data: kioskAntrianRepository.get('TICKET_BOOKING_1')
      })
    },
    infoTicket2 () {
      window.machineKiosk.send({
        type: 'on:info:booking',
        data: kioskAntrianRepository.get('TICKET_BOOKING_2')
      })
    },
    infoNomor1 () {
      window.machineKiosk.send({
        type: 'on:info:booking',
        data: kioskAntrianRepository.get('PASIEN_PHONE')
      })
    },
    infoNomor2 () {
      window.machineKiosk.send({
        type: 'on:info:booking',
        data: kioskAntrianRepository.get('PASIEN_PHONE_2')
      })
    },
    antrian1 () {
      window.machineKiosk.send({
        type: 'on:info:ticket',
        data: kioskAntrianRepository.get('ANTRIAN_1')
      })
    },
    antrian2 () {
      window.machineKiosk.send({
        type: 'on:info:ticket',
        data: kioskAntrianRepository.get('ANTRIAN_2')
      })
    },
    antrian3 () {
      window.machineKiosk.send({
        type: 'on:info:ticket',
        data: kioskAntrianRepository.get('ANTRIAN_3')
      })
    },
    antrian4 () {
      window.machineKiosk.send({
        type: 'on:info:ticket',
        data: kioskAntrianRepository.get('ANTRIAN_4')
      })
    },
    antrian5 () {
      window.machineKiosk.send({
        type: 'on:info:ticket',
        data: kioskAntrianRepository.get('ANTRIAN_5')
      })
    },
    antrian6 () {
      window.machineKiosk.send({
        type: 'on:info:ticket',
        data: kioskAntrianRepository.get('ANTRIAN_6')
      })
    },
    errorResponse () {
      window.config.set({ type: ENUM_SETUP.INVALID_TICKET })
    }
    // checkValidationTicketFalse () {
    //   window.checkIn.set({ type: ENUM_SETUP.INVALID_TICKET })
    // }
  }
}
</script>
<style>
.position-testing {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 13;
}

.position-stately-test {
  z-index: 20;
  position: absolute;
  top: 0;
  right: -200px;
  height: 100vh;
  width: 200px;
  background: var(--pure-white);
  transition: 0.3s cubic-bezier(0, 0.19, 0.57, 1.15);
  overflow: auto;
}

.transition-show {
  right: 0px;
}

.position-button-right{
  position: absolute;
  z-index: 21;
  top: 0;
  right: 0;
}
</style>
