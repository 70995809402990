import axios from 'axios'
import store from '../store'
const instance = axios

instance.defaults.headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: 0
}

instance.interceptors.request.use(config => {
  config.metadata = { startTime: new Date() }
  return config
}, (error) => {
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  response.config.metadata.endTime = new Date()
  response.duration = response.config.metadata.endTime - response.config.metadata.startTime
  return response
}, (error) => {
  error.config.metadata.endTime = new Date()
  error.duration = error.config.metadata.endTime - error.config.metadata.startTime
  if (error.config.headers.interval) {
    // apabila tidak merupakan interval get
    store.commit('DocumentHandler/SET_IS_OFFLINE', true)
  }
  return Promise.reject(error)
})

export default instance
