export default {
  dataServiceTimeOut () {
    return {
      idleTime: 60,
      logoFooterTimeShow: 7,
      logoFooterTimeHide: 5,
      overlayTableCreateAntrian: 0.5,
      buttonLinearCancelDelay: 2,
      buttonLinearCancelProgress: 10,
      buttonLinearCancelAfter: 2
    }
  },
  dataServiceTimeOutReplace () {
    return {
      idleTime: 60,
      logoFooterTimeShow: 7,
      logoFooterTimeHide: 5,
      overlayTableCreateAntrian: 0.5,
      buttonLinearCancelDelay: 2,
      buttonLinearCancelProgress: 10,
      buttonLinearCancelAfter: 2
    }
  },
  dataServiceKasir () {
    return {
      data: {
        poliWaiting: 'Rehabilitasi Medik Lt.1',
        queue: '0032',
        dokter_wait: [{
          id: 1,
          name: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp.KFR',
          poli: 'Rehabilitasi Medik',
          time: '13: 30'
        },
        {
          id: 2,
          name: 'dr. Tjhin Sun Fu, Sp.B, M.BioMed',
          poli: 'Bedah umum',
          time: '15: 30'
        }]
      },
      status: 200
    }
  },
  dataServiceKasir2 () {
    return {
      data: {
        poliWaiting: '',
        queue: '0032',
        dokter_wait: [{
          id: 1,
          name: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp.KFR',
          poli: 'Rehabilitasi Medik',
          time: '13: 30'
        },
        {
          id: 2,
          name: 'dr. Tjhin Sun Fu, Sp.B, M.BioMed',
          poli: 'Bedah umum',
          time: '15: 30'
        }]
      },
      status: 200
    }
  },
  dataServiceKasir3 () {
    return {
      data: {
        poliWaiting: 'Rehabilitasi Medik Lt.1',
        queue: '0032'
      },
      status: 200
    }
  },
  dataServiceKasir4 () {
    return {
      data: {
        poliWaiting: '',
        queue: '0032'
      },
      status: 200
    }
  },
  dataServiceKasir5 () {
    return {
      data: {
        poliWaiting: '',
        queue: '0032'
      },
      status: 400
    }
  },
  dataServiceFakeBookingCodeSuccess () {
    return {
      data: {},
      status: 200
    }
  },
  dataServiceFakeBookingCodeSuccessWithListDokter () {
    return {
      data: {},
      status: 200
    }
  },
  dataServiceFakePoli () {
    return {
      namePoli: 'Rawat Jalan Pembayaran Pribadi',
      floor: 'Lt1'
    }
  },
  dataServiceListAntrianShow () {
    return {
      data: {
        list: [
          {
            id: 14,
            numberAntrian: '0031',
            status: 'Waiting'
          },
          {
            id: 15,
            numberAntrian: '0032',
            status: 'Waiting'
          },
          {
            id: 16,
            numberAntrian: '0033',
            status: 'Waiting',
            selected: true
          }
        ]
      },
      status: 200
    }
  },
  dataServiceListAntrianShow2 () {
    return {
      data: {
        list: [
          {
            id: 15,
            numberAntrian: '0032',
            status: 'Waiting'
          },
          {
            id: 16,
            numberAntrian: '0033',
            status: 'Waiting',
            selected: true
          },
          {
            id: 17,
            numberAntrian: '0031',
            status: 'Waiting'
          }
        ]
      },
      status: 200
    }
  },
  dataServiceListAntrianShow3 () {
    return {
      data: {
        list: [
          {
            id: 16,
            numberAntrian: '0033',
            status: 'Waiting',
            selected: true
          }
        ]
      },
      status: 200
    }
  },
  dataServiceListAntrianShow4 () {
    return {
      data: {
        list: [
          {
            id: 16,
            numberAntrian: '0033',
            status: 'Waiting',
            selected: true
          }
        ]
      },
      status: 400
    }
  },
  dataServiceBookingan () {
    return {
      data: {
        today: true,
        queue: '0032',
        dokter: [{
          id: 1,
          name: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp.KFR',
          poli: 'Rehabilitasi Medik',
          time: 'Selasa, 28 Juni 2022 10:30 WIB'
        },
        {
          id: 2,
          name: 'dr. Tjhin Sun Fu, Sp.B, M.BioMed',
          poli: 'Bedah umum',
          time: 'Selasa, 28 Juni 2022 13:30 WIB'
        },
        {
          id: 3,
          name: 'dr. Nancy Indah Lestari, SpPD',
          poli: 'Penyakit Dalam',
          time: 'Selasa, 28 Juni 2022 15:30 WIB'
        }],
        qrCode: 'antrian 1234'
      },
      status: 200
    }
  },
  dataServiceBookingan2 () {
    return {
      data: {
        today: true,
        queue: '0033',
        dokter: [{
          id: 1,
          name: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp.KFR',
          poli: 'Rehabilitasi Medik',
          time: 'Selasa, 28 Juni 2022 10:30 WIB'
        }]
      },
      status: 200
    }
  },
  dataServiceBookingan3 () {
    return {
      data: {
        today: false,
        queue: '0033',
        dokter: [{
          id: 1,
          name: 'dr. Kevin Triangto, BMedSc. (Hons.), Sp.KFR',
          poli: 'Rehabilitasi Medik',
          time: 'Selasa, 28 Juni 2022 10:30 WIB'
        }]
      },
      status: 200
    }
  },
  dataServiceBookingan4 () {
    return {
      data: {
        message: 'Not Found'
      },
      status: 400
    }
  },
  dataServiceVideoHome2 () {
    return [
      {
        id: 13,
        src: 'https://static.vecteezy.com/system/resources/previews/004/551/804/mp4/medical-assistant-preparing-hospital-ward-bed-for-patient-free-video.mp4'
      },
      {
        id: 10,
        src: 'https://www.adobe.com/express/feature/video/convert/media_1910b1ecad7c643b73930a81939bef959f9033d3d.mp4'
      },
      {
        id: 11,
        src: 'https://static.vecteezy.com/system/resources/previews/005/645/052/mp4/empty-corridor-in-hospital-with-chairs-video.mp4'
      },
      {
        id: 14,
        src: 'https://static.vecteezy.com/system/resources/previews/001/291/209/mp4/traffic-on-multiple-lane-highway-video.mp4'
      },
      {
        id: 15,
        src: 'https://static.vecteezy.com/system/resources/previews/002/031/428/mp4/close-up-doctor-s-hand-filling-a-syringe-with-vaccines-at-the-hospital-free-video.mp4'
      },
      {
        id: 16,
        src: 'https://static.vecteezy.com/system/resources/previews/005/323/324/mp4/scientist-team-has-researching-in-the-laboratory-meeting-and-writing-analysis-results-of-coronavirus-vaccine-development-in-the-laboratory-professional-doctor-working-research-in-biotechnology-term-free-video.mp4'
      },
      {
        id: 17,
        src: 'https://static.vecteezy.com/system/resources/previews/003/077/457/mp4/little-girl-in-face-mask-looking-out-window-free-video.mp4'
      }
    ]
  },
  dataServiceVideoHome () {
    return [
      {
        id: 19,
        src: 'http://192.168.108.27/video/20210917MRHPVideoReqMarketing.mp4'
      },
      {
        id: 20,
        src: 'http://192.168.108.27/video/dr.kevincomhopress.mkv'
      },
      {
        id: 21,
        src: 'http://192.168.108.27/video/210801CaraMemakaiMaskerYangBenarv2.mp4'
      },
      {
        id: 22,
        src: 'http://192.168.108.27/video/210801MRHPCaraMencuciTangan.mp4'
      },
      {
        id: 23,
        src: 'http://192.168.108.27/video/kompresmrhp/mrhpnowopen.mp4'
      }
    ]
  },
  cardTypeDetailDefault () {
    return [
      {
        id: 'BOOKING',
        icon: require('@/assets/icon/pendaftaran.svg'),
        header: 'Pendaftaran',
        info: 'Saya',
        class: 'c-hijau',
        booking: 'sudah booking',
        description: 'descriptionBooking',
        action: 'TO_INPUT_KEYPAD'
      },
      {
        id: 'PENDAFTARAN',
        icon: require('@/assets/icon/pendaftaran.svg'),
        header: 'Pendaftaran',
        info: 'Saya',
        class: 'not-success',
        booking: 'belum booking',
        description: 'descriptionPendaftaran',
        action: 'TO_PRINT'
      }
    ]
  },
  cardTypeOther () {
    return [{
      id: 'kasir',
      icon: require('@/assets/icon/kasir.svg'),
      header: 'Kasir',
      info: 'Kasir Pembayaran Pribadi Lt.1',
      description: 'Klik disini untuk scan QR Code',
      action: 'SHOW_KASIR'
    }]
  }
}
