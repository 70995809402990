import DataFake from './dummy-data'
import APIs from '@/services/ApiWithResponseHandle'
import DATA from '../services/static-data'
import lang from '@/services/Language'
// import axios from 'axios'
export default {
  getPingServer () {
    return new Promise((resolve, reject) => {
      APIs.get(DATA.KIOSK_VAR.API_PING, {
        headers: {
          interval: true
        }
      })
        .then(_data => {
          resolve(_data)
        })
        .catch(err => {
          reject(new Error(err.response))
        })
    })
  },
  getConfigPoli () {
    return new Promise((resolve, reject) => {
      if (localStorage.configPoli) {
        resolve(JSON.parse(localStorage.configPoli))
      } else {
        reject(new Error('data not support'))
      }
    })
  },
  setConfigPoli (config) {
    return new Promise((resolve, reject) => {
      try {
        const data = DataFake.dataServiceFakePoli()
        localStorage.setItem('configPoli', JSON.stringify(data))
        localStorage.setItem('component-card', JSON.stringify(['pendaftaran', 'booking']))
        resolve(config)
      } catch {
        reject(new Error('data not support'))
      }
    })
  },
  getListAntrian () {
    return new Promise((resolve) => {
      setTimeout(() => {
        const randomId = Math.floor(Math.random() * 4)
        switch (randomId) {
          case 0:
            resolve(DataFake.dataServiceListAntrianShow())
            break
          case 1:
            resolve(DataFake.dataServiceListAntrianShow2())
            break
          case 2:
            resolve(DataFake.dataServiceListAntrianShow3())
            break
          default:
            resolve(DataFake.dataServiceListAntrianShow4())
            break
        }
      }, 3000)
    })
  },
  getBookingListDokter () {
    return new Promise((resolve) => {
      setTimeout(() => {
        const randomId = Math.floor(Math.random() * 4)
        switch (randomId) {
          case 0:
            resolve(DataFake.dataServiceBookingan())
            break
          case 1:
            resolve(DataFake.dataServiceBookingan2())
            break
          case 2:
            resolve(DataFake.dataServiceBookingan3())
            break
          default:
            resolve(DataFake.dataServiceBookingan4())
            break
        }
      }, 3000)
    })
  },
  async getListVideoInAPI (link) {
    // return new Promise((resolve, reject) => {
    //   // if (DataFake.dataServiceVideoHome().length < 1) {
    //   //   return setTimeout(() => reject(new Error('Video Not List')), 1500)
    //   // }
    //   setTimeout(() => {
    //     resolve(DataFake.dataServiceVideoHome())
    //   }, 2000)
    // })
    return fetch(link)
      .then(resp => resp.text())
      .then(data => {
        const list = data.replaceAll('\r', '').split('\n')
        const listData = list.map(item => {
          return {
            id: item,
            src: item
          }
        })
        console.log(listData)
        return listData
      })
  },
  async getDatabase (database, path) {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(database)
      request.onerror = event => {
        console.log('database error')
        reject(new Error('error connect'))
      }
      request.onsuccess = event => {
        console.log('database connect')
        resolve(event.target.result)
      }

      request.onupgradeneeded = event => {
        const database = event.target.result
        database.createObjectStore(path, {
          keyPath: 'id'
        })
      }
    })
  },
  readDataAssetDatabase (database, item) {
    return new Promise((resolve, reject) => {
      const transaction = database.transaction(['link'], 'readwrite')
      const store = transaction.objectStore('link')
      const request = store.get(item)

      request.onerror = () => {
        reject(new Error('not get Reqest'))
      }
      request.onsuccess = event => {
        resolve(request.result)
      }
    })
  },
  SaveDataToDatabase (database, item) {
    return new Promise((resolve, reject) => {
      const request = database.transaction(['link'], 'readwrite')
      const store = request.objectStore('link')
      store.put(item)

      request.onsuccess = () => resolve(true)
      request.onerror = () => reject(new Error('gagal save to Database'))
    })
  },
  getAfterSaveDatabase (database, objStore, item) {
    return new Promise((resolve) => {
      const request = database.transaction(['link'], 'readwrite')
        .objectStore('link')
      const store = request.get(objStore)

      store.onsuccess = () => {
        request.put(item)
        resolve(true)
      }
    })
  },
  deleteDataInDatabase (database, item) {
    return new Promise((resolve, reject) => {
      const request = database.transaction(['link'], 'readwrite')
        .objectStore('link')
        .delete(item)

      request.onsuccess = () => resolve(true)
      request.onerror = () => reject(new Error('gagal mendelete Data'))
    })
  },
  downloadFitur (url) {
    return new Promise((resolve, reject) => {
      // axios.get(url, {
      //   responseType: 'arraybuffer',
      //   headers: {
      //     'Access-Control-Allow-Origin': '*'
      //   }
      // })
      //   .then(_data => {
      //     console.log(_data)
      //   })
      //   .catch(err => console.log('err', err.response))

      fetch(url, {
        // mode: 'cors'
      })
        .then(resp => resp.blob())
        .then(myBlob => {
          resolve(myBlob)
        })
        .catch(err => reject(new Error(err.response)))
    })
  },
  getCardComponent () {
    return new Promise((resolve, reject) => {
      const getLocalStorage = JSON.parse(localStorage.getItem('component-card'))
      let dataResult = []
      const data = DataFake.cardTypeDetailDefault()
      try {
        const preview = data.reduce((prev1, prev2) => {
          const indexData = getLocalStorage.findIndex(item => item === DATA.COMPONENT_CARD[prev2.id])
          if (indexData >= 0) {
            prev1.push(prev2)
          }
          return prev1
        }, [])
        dataResult = preview.map(item => {
          return {
            ...item,
            header: lang.detectLanguage(item.header),
            info: lang.detectLanguage(item.info),
            booking: lang.detectLanguage(item.booking),
            description: lang.detectLanguage(item.description)
          }
        })
      } catch {

      }
      resolve(dataResult)
      // resolve([])
    })
  },
  getOtherComponent () {
    let data = []
    try {
      data = DataFake.cardTypeOther().map(item => {
        return {
          ...item,
          description: lang.detectLanguage(item.description)
        }
      })
    } catch {

    }
    return new Promise((resolve, reject) => {
      resolve(data)
    })
  }
}
