import ENUM_SETUP from '../enum/typeSetup'

class checkURL {
  getGeneratedIdFromLink (link) {
    const regex = /\?([^%]+)/
    const match = link.match(regex)
    if (match) {
      const capturedText = match[1]
      return capturedText
    }
  }

  mapperTicket (link) {
    const urlOrigin = new URL(link)
    const isGenerated = urlOrigin.pathname === process.env.VUE_APP_PARAMS_GENERATED
    const isCode = urlOrigin.pathname === process.env.VUE_APP_PARAMS_CODE_BOOKING
    const isPrinter = urlOrigin.pathname === process.env.VUE_APP_PARAMS_PRINTER
    if (isGenerated) {
      const generateId = this.getGeneratedIdFromLink(link)
      if (generateId) {
        return {
          type: ENUM_SETUP.TYPE_UNIT,
          generateId
        }
      }
    } else if (isCode) {
      const codeTicket = this.getGeneratedIdFromLink(link)
      if (codeTicket) {
        return {
          type: ENUM_SETUP.TYPE_BOOKING,
          code: codeTicket
        }
      }
    } else if (isPrinter) {
      const generateId = this.getGeneratedIdFromLink(link)
      if (generateId) {
        return {
          type: ENUM_SETUP.TYPE_PRINT,
          generateId
        }
      }
    }
    return false
  }

  getParamsURLWithTicket (url) {
    try {
      const urlOrigin = new URL(url)
      const isGenerated = urlOrigin.pathname === process.env.VUE_APP_PARAMS_GENERATED
      if (isGenerated) {
        const generateId = this.getGeneratedIdFromLink(url)
        if (generateId) {
          return {
            isPasien: true,
            generateId
          }
        }
      }
    } catch {
    }
    return url
  }
}

export default checkURL
