import settingTimeOut from './settingTimeOut'
import fromServer from './from-server'
import kiosk from './Kiosk'
import antrian from './Antrian'
const repositories = {
  dataTimeOut: settingTimeOut,
  fromServer,
  kiosk,
  antrian
}

export const Repository = {
  get: name => repositories[name]
}
