const KIOSK_VAR = {
  API_PING: 'https://pokeapi.co/api/v2/pokemon/ditto',
  BASE_SERVER_KIOSK: 'http://localhost:8089'
}
const MODE_SHOW_QUEUE = {
  ERROR: 'ERROR',
  BOOKING: 'BOOKING',
  QUEUE: 'QUEUE',
  LIST_DOKTER: 'LIST_DOKTER',
  CREATE_QUEUE: 'CREATE_QUEUE'
}

const MODE_COMMAND = {
  OFFLINE: 'offline',
  ONLINE: 'online',
  CLEAR_STORAGE: 'clear localstorage',
  SET_CONFIG: 'set config'
}

const COMPONENT_CARD = {
  BOOKING: 'booking',
  PENDAFTARAN: 'pendaftaran'
}

const DATA = { KIOSK_VAR, MODE_SHOW_QUEUE, MODE_COMMAND, COMPONENT_CARD }
export default { ...DATA }
