import { Repository } from '@/repositories/repository'
import { uuid } from 'vue-uuid'
const kioskRepository = Repository.get('kiosk')
const fromServer = Repository.get('fromServer')

export default {
  namespaced: true,
  state: {
    isOffline: false,
    pingTime: 50,
    isErrorPage: false,
    uniqueUUID: '',
    cardComponent: []
  },
  mutations: {
    SET_CARD_COMPONENT (state, res) {
      state.cardComponent = res
    },
    SET_UNIQUE_UUID (state, res) {
      state.uniqueUUID = res
    },
    SET_IS_OFFLINE (state, res) {
      state.isOffline = res
    },
    SET_PING_TIME (state, res) {
      state.pingTime = res
    },
    SET_ERROR_PAGE (state, res) {
      state.isErrorPage = res
    }
  },
  actions: {
    TOGGLE_DESC_LABEL (context) {
      const descLabel = document.getElementsByClassName('description-label')
      if (descLabel && descLabel.length > 0) {
        for (let i = 0; i < descLabel.length; i++) {
          descLabel[i].classList.toggle('d-none')
        }
      }
    },
    async GET_PING_INTERVAL_SERVER ({ commit }) {
      await kioskRepository.getPingServer()
        .then((_data) => {
          commit('SET_IS_OFFLINE', false)
          commit('SET_PING_TIME', _data.duration)
        })
        .catch(() => {
          console.log('error ping interval')
        })
    },
    async SET_CHECK_UUID ({ commit }) {
      const checkUUIDinLocalstorage = await fromServer.checkLocalStorage('uuid')
      let uuidBrowser = ''
      if (checkUUIDinLocalstorage) {
        uuidBrowser = localStorage.getItem('uuid')
      } else {
        uuidBrowser = uuid.v4()
        localStorage.setItem('uuid', uuidBrowser)
      }
      commit('SET_UNIQUE_UUID', uuidBrowser)
    },
    async SET_CARD_COMPONENTS ({ commit }) {
      let cardComponent
      try {
        cardComponent = await Promise.all([kioskRepository.getCardComponent(), kioskRepository.getOtherComponent()])
          .then(([_data1, _data2]) => {
            _data1.push(..._data2)
            return _data1
          })
      } catch {
        cardComponent = []
      }
      commit('SET_CARD_COMPONENT', cardComponent)
    },
    RESTART_KIOSK (context) {
      fromServer.restartKiosk()
    },
    SHUTDOWN_KIOSK (context) {
      fromServer.shutdownKiosk()
    }
  }
}
