import ENUM_SETUP from '@/enum/typeSetup'
class MoreFunction {
  checkDiffId (arr1, arr2) {
    const isSameUser = (a, b) => a.id === b.id
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(leftValue =>
        !right.some(rightValue =>
          compareFunction(leftValue, rightValue)))
    const onlyInA = onlyInLeft(arr1, arr2, isSameUser)
    return onlyInA
  }

  getChangeLocalStorage (name, value, add) {
    const data = localStorage.getItem(name)
    let result = []
    if (!data && !add) return false
    if (data) {
      let dataParse
      try {
        dataParse = JSON.parse(data)
      } catch {
        dataParse = []
      }
      if (add) {
        const indexData = dataParse.findIndex(item => item === value)
        result = indexData < 0 ? dataParse.push(value) : dataParse
        result = dataParse
      } else {
        result = dataParse.filter(item => item !== value)
      }
    } else {
      result.push(value)
    }
    localStorage.setItem(name, JSON.stringify(result))
  }

  asteriskData (word) {
    if (word.length > 9) {
      return word.replace(word.substr(5, word.length - 8), word.substr(5, word.length - 8).replace(/./g, '*'))
    } else if (word.length <= 9 && word.length >= 4) {
      return word.replace(word.substr(2, word.length - 3), word.substr(2, word.length - 3).replace(/./g, '*'))
    } else if (word.length >= 3) {
      return word.replace(word.substr(2, word.length - 2), word.substr(2, word.length - 2).replace(/./g, '*'))
    } else {
      return word.replace(word.substr(1, word.length - 1), word.substr(1, word.length - 1).replace(/./g, '*'))
    }
  }

  asteriskNamaConvert (word) {
    return word.trim().split(' ').map(item => this.asteriskData(item)).join(' ')
  }

  changeLangwithParam (lang, text, change) {
    if (!lang) return ''
    return lang.toString().replace(change, text)
  }

  createToast (isKiosAntrian = true) {
    let toast = []
    let labelToastShow = ''
    if (isKiosAntrian) {
      toast = ['fs-32', 'label-toast']
      labelToastShow = 'label-toast-show'
    } else {
      toast = ['fs-16', 'label-toast', 'label-toast-kioskCheckIn']
      labelToastShow = 'label-toast-show-kioskCheckIn'
    }
    if (!document.getElementById('toast-error')) {
      const bodyTag = document.body
      const element = document.createElement('label')
      element.textContent = 'QR code tidak dikenali'
      const classList = ['bg-warning-A', 'fw-600', ...toast]
      element.classList.add(...classList)
      element.id = 'toast-error'
      bodyTag.appendChild(element)
      setTimeout(() => {
        document.getElementById('toast-error').classList.add(labelToastShow)
      }, 100)
      setTimeout(() => {
        document.getElementById('toast-error').classList.remove(labelToastShow)
      }, 5000)
      setTimeout(() => {
        document.getElementById('toast-error').remove()
      }, 6000)
    }
  }

  createToastPrinter (isShow, type) {
    let content = ''
    if (ENUM_SETUP.OTHER_ERROR_PRINT === type) {
      content = 'Ada kendala printer!'
    } else if (ENUM_SETUP.EMPTY_PAPER === type) {
      content = 'Kertas tiket habis!'
    }
    let toast = []
    let labelToastShow = ''
    toast = ['fs-32', 'label-toast']
    labelToastShow = 'label-toast-show'
    if (!document.getElementById('toast-error-printer') && isShow) {
      const bodyTag = document.body
      const element = document.createElement('label')
      element.textContent = content
      const classList = ['bg-warning-A', 'fw-600', ...toast]
      element.classList.add(...classList)
      element.id = 'toast-error-printer'
      bodyTag.appendChild(element)
      setTimeout(() => {
        document.getElementById('toast-error-printer').classList.add(labelToastShow)
      }, 100)
    } else if (document.getElementById('toast-error-printer') && !isShow) {
      setTimeout(() => {
        document.getElementById('toast-error-printer').classList.remove(labelToastShow)
      }, 2000)
      setTimeout(() => {
        document.getElementById('toast-error-printer').remove()
      }, 3000)
    }
  }
}

export default MoreFunction
