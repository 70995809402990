/* eslint-disable no-new */
import DataFake from './dummy-data'
import AllTimeKiosk from '@/mappers/DataTimeOut'
export default {
  get () {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(new AllTimeKiosk(DataFake.dataServiceTimeOut()))
      }, 5000)
    })
  },
  getValidationCode () {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DataFake.dataServiceTimeOut())
      }, 5000)
    })
  }
}
